import { FC } from 'react';

type Props = {
  name: string;
  barcode: string;
  thumbnail: null | string;
}

const ProductColumnName: FC<Props> = ({ name, barcode, thumbnail }) => {
  return (
    <div className='flex items-center'>
      <div>
        <img className="h-8 mr-2" src={thumbnail ? thumbnail : process.env.PUBLIC_URL + '/images/product-placeholder.png'} alt="product-thumbnail"/>
      </div>
      <div>
        <div>
          {name.toUpperCase()}
        </div>
        <div>
          {barcode.toUpperCase()}
        </div>
      </div>
    </div>
  )
}

export default ProductColumnName;