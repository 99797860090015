import { Chip, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react'
import { UserCircleIcon, PowerIcon, EnvelopeIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { logout, selectUser } from '../../store/redux/user/user-slice';
import { selectInvitesTotal } from '../../store/redux/invites/invites-slice';

const ProfileMenu = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const totalInvites = useAppSelector(selectInvitesTotal);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  }

  return (
    <Menu>
      <MenuHandler>
        <span className='flex cursor-pointer items-center mr-6'>
          <div className='border h-10 w-10 rounded-full flex mr-2 bg-green-700 text-white justify-center items-center'>{user?.name[0].toUpperCase()}</div>
          <ChevronDownIcon strokeWidth={2} className='h-2 w-2' />
        </span>
      </MenuHandler>
      <MenuList>
        <MenuItem className="flex items-center gap-2">
          <Typography variant="small" className="font-normal">
            {user?.username}
          </Typography>
        </MenuItem>
        <hr className="my-2 border-blue-gray-50" />
        <MenuItem className="flex items-center gap-2">
          <UserCircleIcon strokeWidth={2} className="h-4 w-4" />
          <Typography variant="small" className="font-normal">
            {t('headerProfileLabel')}
          </Typography>
        </MenuItem>
        <MenuItem className="flex items-center gap-2">
          <EnvelopeIcon strokeWidth={2} className="h-4 w-4" />
          <Typography variant="small" className="font-normal mr-2">
            {t('headerInvitesLabel')}
          </Typography>
          {totalInvites > 0 && <Chip value={totalInvites} />}
        </MenuItem>
        <hr className="my-2 border-blue-gray-50" />
        <MenuItem className="flex items-center gap-2" onClick={handleLogout}>
          <PowerIcon strokeWidth={2} className="h-4 w-4" />
          <Typography variant="small" className="font-normal">
            {t('headerLogoutLagel')}
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default ProfileMenu;