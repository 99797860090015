import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import withLayout from '../../components/layout';
import Catalog from '../catalog';
import Clients from '../clients';
import Suppliers from '../suppliers';

const DefaultRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/catalog')
  })
  return <></>
}

const Dashboard = () => {
  return (
    <Routes>
      <Route path='/catalog' element={<Catalog />}/>
      <Route path="/clients" element={<Clients />}/>
      <Route path="/suppliers*" element={<Suppliers />}/>
      <Route path='*' element={<DefaultRoute />} />
    </Routes>
  )
}

export default withLayout(Dashboard);