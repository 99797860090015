import { useAppSelector } from '../store/hooks';
import { selectIam } from '../store/redux/user/user-slice';


const useAuth = () => {
  const iam = useAppSelector(selectIam);
  return iam.user
};

export default useAuth;
