import { FC, PropsWithChildren } from 'react'
import Drawer from '../drawer'
import Footer from '../footer'
import Header from '../header'
import Modal from '../modal'
import Navigation from '../navigation'
import Toast from '../toast'
import Version from '../version'

const withLayout = <P extends PropsWithChildren>(Component: React.ComponentType<P>) => {
  const Layout: FC<PropsWithChildren> = (props) => {
    return (
      <div className='flex flex-col h-screen'>
        <Header />
        <div className='flex h-full'>
          <Navigation />
          <div className='flex-1'>
            <Component {...props as P} />
            <Drawer />
            <Toast />
          </div>
          <Modal />
        </div>
        <Footer />
        <Version />
      </div>
    )
  }
  return Layout;
}


export default withLayout;