import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { AddProductRequest, ListProductsByNameOrBarcodeRequest, ListProductsRequest, State, UpdateProductRequest } from './types';
import { fetchAddProduct, fetchDeleteProduct, fetchUpdateProduct, fetchListProducts, fetchListSchemas, fetchListProductsByNameOrBarcode } from './products-api';
import ProductValueObject, { ProductShape } from '../../../value-objects/product-value-object';
import SchemaValueObject, { SchemaShape } from '../../../value-objects/schema-value-object';

const initialState: State = {
  total: 0,
  products: [],
  schemas: [],
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // setProducts: (state: State, action: PayloadAction<ListProductsResponse>) => {
    //   state.products = action.payload;
    // }
  },

  extraReducers: (builder) => {
    builder
      .addCase(listProductsAsync.fulfilled, (state, action) => {
        state.products = action.payload?.products || [];
        state.total = action.payload?.total || 0
      })
      .addCase(listProductsByNameOrBarcodeAsync.fulfilled, (state, action) => {
        state.products = action.payload?.products || [];
        state.total = action.payload?.total || 0
      })
      .addCase(listSchemasAsync.fulfilled, (state, action) => {
        state.schemas = action.payload;
      })
      .addCase(addProductsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          if (state.products.length < 5) {
            state.products = [...state.products, action.payload]
          }
          state.total = state.total + 1;
        }
      })
  },
});

export const listProductsAsync = createAsyncThunk(
  'products/list',
  async (payload: ListProductsRequest) => {
    try {
      const response = await fetchListProducts(payload);
      const json = await response.json();
      const products = json.products.map((p: ProductShape) => new ProductValueObject({
        schemaId: p.schemaId,
        schemaName: p.schemaName,
        id: p.id,
        name: p.name,
        barcode: p.barcode,
        category: p.category,
        inventoryAmount: p.inventoryAmount,
        status: p.status,
        thumbnail: p.thumbnail,
        customAttributes: p.customAttributes,
      }));
      const total = json.total;
      return {
        products,
        total,
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const addProductsAsync = createAsyncThunk(
  'products/add',
  async (payload: AddProductRequest) => {
    try {
      const response = await fetchAddProduct(payload);
      const p = await response.json();
      return new ProductValueObject({
        schemaId: p.schemaId,
        schemaName: p.schemaName,
        id: p.id,
        name: p.name,
        barcode: p.barcode,
        category: p.category,
        inventoryAmount: p.inventoryAmount,
        status: p.status,
        thumbnail: p.thumbnail,
        customAttributes: p.customAttributes,
      });
    } catch (e) {
      console.log(e);
    }
  }
);

export const listSchemasAsync = createAsyncThunk(
  'schemas/list',
  async () => {
    try {
      const response = await fetchListSchemas();
      const json = await response.json();
      return json.map((p: SchemaShape) => new SchemaValueObject({
        id: p.id,
        name: p.name,
        customAttributes: p.customAttributes,
      }))
    } catch (e) {
      console.log(e);
    }
  }
);

export const deleteProductAsync = createAsyncThunk(
  'products/delete',
  async (id: string) => {
    try {
      const response = await fetchDeleteProduct(id);
      const json = await response.json();
      return json.id;
    } catch (e) {
      console.log(e);
    }
  }
);

export const updateProductAsync = createAsyncThunk(
  'products/update',
  async (payload: UpdateProductRequest) => {
    try {
      const response = await fetchUpdateProduct(payload);
      const json = await response.json();
      return json;
    } catch (e) {
      console.log(e);
    }
  }
);

export const listProductsByNameOrBarcodeAsync = createAsyncThunk(
  'products/search',
  async (payload: ListProductsByNameOrBarcodeRequest) => {
    try {
      const response = await fetchListProductsByNameOrBarcode(payload);
      const json = await response.json();
      const products = json.products.map((p: ProductShape) => new ProductValueObject({
        schemaId: p.schemaId,
        schemaName: p.schemaName,
        id: p.id,
        name: p.name,
        barcode: p.barcode,
        category: p.category,
        inventoryAmount: p.inventoryAmount,
        status: p.status,
        thumbnail: p.thumbnail,
        customAttributes: p.customAttributes,
      }));
      const total = json.total;
      return {
        products,
        total,
      }
    } catch (e) {
      console.log(e);
    }
  }
);

// Reducer
export default productsSlice.reducer;
// Actions
// export const { setProducts } = productsSlice.actions;

// Selectors
export const selectProducts = (state: RootState) => state.products.products;
export const selectTotalProducts = (state: RootState) => state.products.total;
export const selectSchemas = (state: RootState) => state.products.schemas;

