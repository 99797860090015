import ViewRegister from '../../components/register-view';

const Register = () => {
    return (
        <div className='bg-blue-900 min-h-screen min-w-screen flex justify-center items-center flex-1'>
          <ViewRegister />
        </div>
    )
}

export default Register;