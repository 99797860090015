import { useEffect } from 'react';
import { useDrawer } from '../../providers/drawer-provider';
import { SvgCross } from '../svg';

const Drawer = () => {
  const drawer = useDrawer();

  useEffect(() => {
    const closeDrawerEscDown = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') {
        return;
      }
      drawer.setIsOpen(false);
    }

    window.addEventListener('keydown', closeDrawerEscDown);
    return () => {
      window.removeEventListener('keydown', closeDrawerEscDown);
    }
    // eslint-disable-next-line
  }, [drawer.isOpen])

  if (!drawer.isOpen) {
    return null;
  }

  if (!drawer.Component) {
    return null;
  }

  const handleCloseModal = () => {
    drawer.setIsOpen(false);
  }

  return (
    <div className='bg-white border flex flex-col w-96 h-full absolute top-0 right-0 overflow-auto hover:overflow-scroll'>
      <div className='h-16 flex items-center p-2 pl-6 text-gray-500'>
        <span onClick={handleCloseModal} className='cursor-pointer'><SvgCross /></span>
        <span className='flex-1 text-center'>{drawer.title}</span>
      </div>
      <div className='flex-1'>
        {drawer.Component}
      </div>
    </div>
  );
}

export default Drawer;