import { useToast } from '../../providers/toast-provider';
import ToastColorValueObject from '../../value-objects/toast-color-value-object';
import { SvgCross } from '../svg';
import ToastOperationIndication from './toast-operation-indication';

const Toast = () => {
  const toast = useToast();

  if (!toast.isOpen) {
    return null;
  }

  const color = new ToastColorValueObject(toast.color)

  const handleCloseModal = () => {
    toast.setIsOpen(false);
  }

  return (
    <div className={`z-10 border flex items-center w-1/4 h-14 bg-white absolute bottom-0 left-0 ml-4 mb-4 rounded-md text-sm border-${color.value}`}>
      <div className='flex flex-1 pr-2 pl-2'>
        <div className={`bg-${color.light()} border-black h-7 w-7 rounded flex justify-center items-center`}>
          <span>
            <ToastOperationIndication
              color={color.hex()}
              operation={toast.color}
            />
          </span>
        </div>
        <div className={`flex-1 ml-2 p-1 text-${color.value}`}>
          {toast.text}
        </div>
        <div className='flex items-center'>
          <span onClick={handleCloseModal} className='cursor-pointer'>
            <SvgCross fill={color.hex()} />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Toast;