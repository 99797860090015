import { Checkbox } from '@material-tailwind/react'
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '../../providers/drawer-provider';
import ClientValueObject from '../../value-objects/client-value-object';
import ViewClient from '../view-client';
import ClientColumnGeneral from './client-column-general';
import ClientColumnName from './client-column-name';
import ClientColumnStatus from './client-column-status';

type Props = {
  client: ClientValueObject;
  isInvite: boolean;
}

const ClientItem: FC<Props> = ({ client, isInvite }) => {
  const drawer = useDrawer();
  const { t } = useTranslation();

  const handleSelectClient = () => {
    drawer.setIsOpen(true);
    drawer.setTitle(t('viewClientTitle'))
    drawer.setComponent(<ViewClient client={client} isInvite={isInvite} />)
  }

  // TODO: handle pending orders
  return (
    <div onClick={handleSelectClient} className='grid grid-cols-12 mt-4 h-10 text-gray-500 pb-10 text-sm cursor-pointer hover:bg-gray-50'>
      <div className='col-span-1 flex justify-center items-center'><Checkbox onClick={() => {}}/></div>
      <div className='col-span-4 flex justify-start items-center'><ClientColumnName name={client.contactName} clientId={client.id} /></div>
      <div className='col-span-4 flex justify-start items-center'><ClientColumnGeneral label={isInvite ? '-' : 0} /></div>
      <div className='col-span-2 flex justify-start items-center'><ClientColumnStatus status={client.status} /></div>
    </div>
  )
}

export default ClientItem