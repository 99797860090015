class ToastColorValueObject {
  readonly value: string;
 
  constructor(private color: 'success' | 'error') {
    this.value = color === 'success' ? 'green-500' : 'red-500';
  }

  hex() {
    return this.color === 'success' ? '#0E9F6E' : '#EF4444'
  }

  light() {
    return this.color === 'success' ? 'green-100' : 'red-100';
  }
}

export default ToastColorValueObject;