import { FC } from 'react';

type Props = {
  fill?: string;
}

export const SvgCross: FC<Props> = ({ fill }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.86373 3.86375C4.0325 3.69502 4.26138 3.60024 4.50003 3.60024C4.73868 3.60024 4.96755 3.69502 5.13633 3.86375L9.00003 7.72745L12.8637 3.86375C12.9468 3.77779 13.0461 3.70923 13.1559 3.66206C13.2657 3.61489 13.3838 3.59006 13.5033 3.58902C13.6228 3.58798 13.7413 3.61076 13.8519 3.65601C13.9625 3.70126 14.063 3.76809 14.1475 3.85259C14.232 3.9371 14.2988 4.03758 14.3441 4.14819C14.3893 4.2588 14.4121 4.37731 14.4111 4.49681C14.41 4.61631 14.3852 4.73441 14.338 4.84421C14.2909 4.95402 14.2223 5.05333 14.1363 5.13635L10.2726 9.00005L14.1363 12.8637C14.3003 13.0335 14.391 13.2608 14.3889 13.4968C14.3869 13.7328 14.2922 13.9585 14.1254 14.1254C13.9585 14.2923 13.7328 14.3869 13.4968 14.389C13.2608 14.391 13.0335 14.3003 12.8637 14.1363L9.00003 10.2726L5.13633 14.1363C4.96659 14.3003 4.73924 14.391 4.50327 14.389C4.26729 14.3869 4.04156 14.2923 3.87469 14.1254C3.70782 13.9585 3.61317 13.7328 3.61112 13.4968C3.60907 13.2608 3.69979 13.0335 3.86373 12.8637L7.72743 9.00005L3.86373 5.13635C3.695 4.96757 3.60022 4.7387 3.60022 4.50005C3.60022 4.2614 3.695 4.03252 3.86373 3.86375Z" fill={fill || '#6B7280'} />
    </svg>

  )
}