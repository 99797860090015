import { Chip } from '@material-tailwind/react';
import { colors } from '@material-tailwind/react/types/generic';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  status: 'PUBLIC' | 'DRAFT' | 'OUT_OF_STOCK';
}

class StatusValueObject {
  constructor(private status: 'PUBLIC' | 'DRAFT' | 'OUT_OF_STOCK') { }

  private readonly Colors: Record<string , colors> = {
    PUBLIC: 'green',
    DRAFT: 'amber',
    OUT_OF_STOCK: 'red'
  }

  private readonly Labels = {
    PUBLIC: 'statusLabelPublic',
    DRAFT: 'statusLabelDraft',
    OUT_OF_STOCK: 'statusLabelOutOfStock'
  }

  private statusColorMapper(): string {
    const color = this.Colors[this.status]
    if (!color) {
      throw new Error(`${this.status} is invalid`);
    }
    return color;
  }

  private statusLabelMapper(): string {
    const label = this.Labels[this.status];
    if (!label) {
      throw new Error(`${this.status} is invalid`);
    }
    return label; 
  }

  toValue() {
    return {
      color: this.statusColorMapper(),
      status: this.statusLabelMapper(),
    }
  }
}

const ProductColumnStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const valueObject = new StatusValueObject(status);

  return (
    <Chip
      className='normal-case font-normal'
      // @ts-ignore
      color={valueObject.toValue().color}
      // @ts-ignore
      value={t(valueObject.toValue().status)}
    />
  )
}

export default ProductColumnStatus;