import { RequestAddNewClientShape, RequestChangeClientStatusShape } from './types'

export function fetchListClients() {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/clients/list`, {
    method: 'GET',
    headers: {
    },
    credentials: 'include',
  })
}

export function fetchAddNewClient(payload: RequestAddNewClientShape) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/invites/add`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
}

export function fetchChangeClientStatus(payload: RequestChangeClientStatusShape) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/clients/status`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(payload),
  })
}