import { Button } from '@material-tailwind/react';
import { ChangeEventHandler, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '../../providers/drawer-provider';
import { useToast } from '../../providers/toast-provider';
import { useAppDispatch } from '../../store/hooks';
import { addProductsAsync } from '../../store/redux/products/products-slice';
import { AddProductRequest } from '../../store/redux/products/types';
import { ProductCustomAttributesShape } from '../../value-objects/product-value-object';
import SchemaValueObject from '../../value-objects/schema-value-object';
import DropZone from '../drop-zone';
import Input from '../input';
import NewCustomAttributes from './new-custom-attributes';

type Props = {
  schemas: SchemaValueObject[];
}

const AddProduct: FC<Props> = ({ schemas }) => {
  const [product, setProduct] = useState<Partial<AddProductRequest>>({});
  const { t } = useTranslation();
  const [selectedCustomAttributes, setSelectionCustomAttributes] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const drawer = useDrawer();
  const toast = useToast();

  useState(() => {
    if (schemas.length > 0) {
      setSelectionCustomAttributes(schemas[0].getSchemaId());
    }
  })

  const onCustomAttributesSelection: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setProduct((state) => {
      return {
        ...state,
        customAttributes: undefined,
      }
    })
    if (e.currentTarget.value === null || e.currentTarget.value === 'add') {
      setSelectionCustomAttributes(null);
      return;
    }
    setSelectionCustomAttributes(e.currentTarget.value);
  }

  const handleOnCustomAttributeChange = (attribute: string, value: string) => {
    setProduct((state) => {
      const customAttributes: ProductCustomAttributesShape[] = state.customAttributes || [];
      const attributeIndex = customAttributes.findIndex(a => a.attribute === attribute);

      let replace = true;
      if (attributeIndex < 0) {
        replace = false;
      }

      const customAttribute = { attribute, value }

      const newCustomAttributes = Array.from(customAttributes)
      if (replace) {
        newCustomAttributes[attributeIndex] = customAttribute;
      } else {
        newCustomAttributes.push(customAttribute)
      }

      return {
        ...state,
        customAttributes: newCustomAttributes,
      }
    })
  }

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setProduct((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      }
    })
  }

  const handleSaveProduct = async () => {
    if (!product.name || !product.barcode || !product.category || !product.inventoryAmount || !product.status) {
      toast.openToast(t('addProductToastMissingFields'), 'error');
      return;
    }
    const request: AddProductRequest = {
      schemaId: null,
      name: product.name,
      barcode: product.barcode,
      category: product.category,
      inventoryAmount: product.inventoryAmount,
      status: product.status,
      customAttributes: [],
    }

    // handle custom attributes
    if (selectedCustomAttributes) {
      request.schemaId = selectedCustomAttributes;
      request.customAttributes = product.customAttributes || [];
    }

    await dispatch(addProductsAsync(request));
    drawer.setIsOpen(false);
    toast.openToast(t('addProductToastSuccess'), 'success');
  }

  return (
    <div className='flex'>
      <form className='w-full'>
        <ol className='flex flex-col p-4'>
          <li className='mb-6'>
            <Input name="name" classes='w-full' id='add-product-name' onChange={handleOnChange} placeholder={t('addProductNameLabel')} />
          </li>
          <li className='mb-6'>
            <Input name="category" classes='w-full' id='add-product-category' onChange={handleOnChange} placeholder={t('addProductCategoryLabel')} />
          </li>
          <li className='mb-6'>
            <Input name="barcode" classes='w-full' id='add-product-barcode' onChange={handleOnChange} placeholder={t('addProductBarcodeLabel')} />
          </li>
          <li className='mb-6'>
            <Input name="inventoryAmount" type='number' classes='w-full' id='add-product-inventory-amount' onChange={handleOnChange} placeholder={t('addProductInventoryAmountLabel')} />
          </li>
          <li className='mb-6'>
            <div className='block mb-2 text-sm font-medium text-gray-900'>
              {t('editProductStatusLabel')}
            </div>
            <span className='text-sm'>
              <span className='mr-2'>
                <input
                  onChange={handleOnChange}
                  className='border mr-2'
                  id='status-public'
                  name='status'
                  type='radio'
                  value='PUBLIC'></input>
                <label htmlFor='status-public'>{t('editProductStatusPublicValue')}</label>
              </span>
              <span className='mr-2'>
                <input
                  onChange={handleOnChange}
                  className='border mr-2'
                  id='status-draft'
                  name='status'
                  type='radio'
                  value='DRAFT' />
                <label htmlFor='status-draft'>{t('editProductStatusDraftValue')}</label>
              </span>
              <span className='mr-2'>
                <input
                  onChange={handleOnChange}
                  className='border mr-2'
                  id='status-out-of-stock'
                  name='status'
                  type='radio'
                  value='OUT_OF_STOCK'
                />
                <label htmlFor='status-out-of-stock'>{t('editProductStatusOutOfStockValue')}</label>
              </span>
            </span>
          </li>
          <li>
            <span className='block mb-6 text-sm font-medium text-gray-900 w-full' >
              <select onChange={onCustomAttributesSelection} className='border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 bg-gray-50 dark:focus:ring-blue-500 dark:focus:border-blue-500' id='addProductSelectAddtionalFields'>
                {schemas.map(s => {
                  return (<option key={s.getSchemaId()} value={s.getSchemaId()}>{s.getSchemaName()}</option>)
                })}
                <option value='none' defaultValue='none'>{t('addProductSelectAdditionalFields')}</option>
                <option value='add'>{t('addProductAddAddtionalFieldSelectionLabel')}</option>
              </select>
              <NewCustomAttributes
                selectedSchema={selectedCustomAttributes}
                schemas={schemas}
                onCustomAttributeChange={handleOnCustomAttributeChange}
              />
            </span>
          </li>
          <li className='mb-6'>
            <span className='block mb-2 text-sm font-medium text-gray-900'>
              {t('editProductImageLabel')}
            </span>
            <DropZone />
          </li>
          <li>
            <Button onClick={handleSaveProduct} className='items-center normal-case font-normal w-full'>{t('addProductButtonAddLabel')}</Button>
          </li>
        </ol>
      </form>
    </div>
  )
}

export default AddProduct;