import './App.css';
import './i18n/i18n-config';
import Login from './containers/login'
import AuthProvider from './providers/auth-provider';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/protected-route';
import Dashboard from './containers/dashboard';
import Register from './containers/register';
import { DrawerProvider } from './providers/drawer-provider';
import { ToastProvider } from './providers/toast-provider';
import { ModalProvider } from './providers/modal-provider';

function App() {
  return (
    <AuthProvider>
      <ModalProvider>
        <ToastProvider>
          <DrawerProvider>
            <Routes>
              <Route
                path="/login"
                element={<Login />}
              />
              <Route
                path="/register"
                element={<Register />}
              />
              <Route element={<ProtectedRoute />}>
                <Route
                  path="*"
                  element={<Dashboard />}
                />
              </Route>
            </Routes>
          </DrawerProvider>
        </ToastProvider>
      </ModalProvider>
    </AuthProvider>
  );
}

export default App;
