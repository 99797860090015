import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../providers/toast-provider';
import { useAppDispatch } from '../../store/hooks';
import { clientStatusChangeAsync, listClientsAsync } from '../../store/redux/clients/clients-slice';
import ClientValueObject from '../../value-objects/client-value-object';
import Input from '../input';

type Props = {
  client: ClientValueObject;
  isInvite: boolean;
}

const ViewClient: FC<Props> = ({ client, isInvite }) => {
  const { t } = useTranslation();
  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({})
  const dispatch = useAppDispatch();
  const toast = useToast();

  useEffect(() => {
    if (inputRefs.current?.name) {
      inputRefs.current.name.value = client.bussinesName;
    }

    if (inputRefs.current?.email) {
      inputRefs.current.email.value = client.email;
    }

    if (inputRefs.current?.contactName) {
      inputRefs.current.contactName.value = client.contactName;
    }

    if (inputRefs.current?.phoneNumber) {
      inputRefs.current.phoneNumber.value = client.phone;
    }

    if (!isInvite) {
      const clientStatus = client.status;
      if (inputRefs.current?.statusActive) {
        inputRefs.current.statusActive.checked = clientStatus === inputRefs.current.statusActive.value;
      }
  
      if (inputRefs.current?.statusSuspended) {
        inputRefs.current.statusSuspended.checked = clientStatus === inputRefs.current.statusSuspended.value;
      }
    }
  }, [client, isInvite]);

  const handleClientStatusChange = async (status: 'ACTIVE' | 'SUSPENDED') => {
    await dispatch(clientStatusChangeAsync({
      clientId: client.id,
      status: status
    }));
    await dispatch(listClientsAsync());
    toast.openToast(`${t('viewClientChangeClientStatus')} to ${status.toLowerCase()}`, 'success');
  }

  return (
    <div className='h-full flex flex-col'>
      <div className='flex-1'>
        <form className='w-full'>
          <ol className='flex flex-col p-4'>
            <li className='mb-4'>
              <Input
                name='name'
                ref={(ref) => (inputRefs.current.name = ref)}
                label={t('viewClientNameInputLabel')}
                classes="w-full"
                id='view-client-name'
                placeholder={t('viewClientNameInputPlaceholder')}
                disabled
              />
            </li>
            <li className='mb-4'>
              <Input
                name='email'
                ref={(ref) => (inputRefs.current.email = ref)}
                label={t('viewClientEmailInputLabel')}
                classes="w-full"
                id='view-client-email'
                placeholder={t('viewClientEmailInputPlaceholder')}
                disabled
              />
            </li>
            <li className='mb-4'>
              <Input
                name='contactName'
                ref={(ref) => (inputRefs.current.contactName = ref)}
                label={t('viewClientContactNameInputLabel')}
                classes="w-full"
                id='view-client-contact-name'
                placeholder={t('viewClientContactNameInputPlaceholder')}
                disabled
              />
            </li>
            <li className='mb-4'>
              <Input
                name='phoneNumber'
                ref={(ref) => (inputRefs.current.phoneNumber = ref)}
                label={t('viewClientPhoneNumberInputLabel')}
                classes="w-full"
                id='view-client-phone-number'
                placeholder={t('viewClientPhoneNumberInputPlaceholder')}
                disabled
              />
            </li>
            <li className={`mb-4 ${isInvite ? 'hidden' : ''}`}>
              <div className='block mb-2 text-sm font-medium text-gray-900'>
                {t('editProductStatusLabel')}
              </div>
              <span className='text-sm'>
                <span className='mr-2'>
                  <input
                    ref={(ref) => (inputRefs.current.statusActive = ref)}
                    className='border mr-2'
                    id='status-active'
                    name='status'
                    type='radio'
                    value='ACTIVE'
                    onChange={() => {handleClientStatusChange('ACTIVE')}}
                  />
                  <label htmlFor='status-active'>{t('viewClientStatusActiveValue')}</label>
                </span>
                <span className='mr-2'>
                  <input
                    ref={(ref) => (inputRefs.current.statusSuspended = ref)}
                    className='border mr-2'
                    id='status-suspended'
                    name='status'
                    type='radio'
                    value='SUSPENDED'
                    onChange={() => {handleClientStatusChange('SUSPENDED')}}
                  />
                  <label htmlFor='status-suspended'>{t('viewClientStatusSuspendedValue')}</label>
                </span>
              </span>
            </li>
          </ol>
        </form>
      </div>
    </div>
  )
}

export default ViewClient;