import React, { FunctionComponent, PropsWithChildren, useContext, useState } from 'react';

interface DrawerContextProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  Component: JSX.Element | null;
  setComponent: (component: JSX.Element) => void;
  title: string,
  setTitle: (value: string) => void;
}

export const DrawerContext = React.createContext<DrawerContextProps>(undefined!);

const DrawerProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [Component, setComponent] = useState<JSX.Element | null>(null);

  return (
    <DrawerContext.Provider value={{
      setIsOpen,
      isOpen,
      Component,
      setComponent,
      title,
      setTitle,
    }}>
      {children}
    </DrawerContext.Provider>
  );
};

// Custom context hook
const useDrawer = () => {
  return useContext(DrawerContext);
};

export { DrawerProvider, useDrawer };
