import { useModal } from '../../providers/modal-provider';
import { SvgCross } from '../svg';

const Modal = () => {
  const modal = useModal();

  if (!modal.isOpen) {
    return null;
  }

  if (!modal.Component) {
    return null;
  }

  const handleCloseModal = () => {
    modal.setIsOpen(false);
  }

  return (
    <div onClick={handleCloseModal} className='bg-black/50 w-full h-full absolute overflow-auto hover:overflow-scroll top-0 flex justify-center items-center'>
      <div onClick={e => e.stopPropagation()} className='bg-white rounded  w-96 min-h-fit min-w-fit'>
        <div className='p-4 flex items-center content-center border-b'>
          <div className='flex-1 font-bold'>{modal.title}</div>
          <div className='flex items-center cursor-pointer' onClick={handleCloseModal}>
            <span><SvgCross /></span>
          </div>
        </div>
        <div>
          {modal.Component}
        </div>
      </div>
    </div>
  );
}

export default Modal;