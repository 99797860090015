import { FC } from 'react';
import { SvgCross, SvgSuccess } from '../svg';

type Props = {
  operation: 'success' | 'error';
  color: string;
}

const ToastOperationIndication: FC<Props> = ({ operation, color }) => {
  if (operation === 'error') {
    return <SvgCross fill={color} />
  }

  return <SvgSuccess fill={color} />
}

export default ToastOperationIndication;