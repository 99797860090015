import { Checkbox } from '@material-tailwind/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import { selectProducts, selectSchemas, selectTotalProducts } from '../../store/redux/products/products-slice';
import CatalogProduct from '../catalog-product';
import TableFooter from '../table/table-footer';

type Props = {
  page: number;
  setPage: (page: number) => void;
}

const ProductsTable: FC<Props> = ({ setPage, page }) => {
  const schemas = useAppSelector(selectSchemas);
  const products = useAppSelector(selectProducts);
  const totalProducts = useAppSelector(selectTotalProducts);
  const { t } = useTranslation();

  const handleOnPageChange = (toPage: number) => {
    if (toPage < 1) {
      return;
    }

    if (toPage > Math.ceil(totalProducts / 5)) {
      return;
    }

    setPage(toPage);
  }

  return (
    <>
      <div className='grid grid-cols-12 mt-4 border-b-2 h-10 text-gray-500 text-sm'>
        <div className='bg-slate-100 col-span-1 rounded-tl-lg flex justify-center items-center'><Checkbox /></div>
        <div className='bg-slate-100 col-span-3 flex justify-start items-center'>{t('tableHeaderNameLabel')}</div>
        <div className='bg-slate-100 col-span-3 flex justify-start items-center'>{t('tableHeaderCategoryLabel')}</div>
        <div className='bg-slate-100 col-span-3 flex justify-start items-center'>{t('tableHeaderInventoryAmountLabel')}</div>
        <div className='bg-slate-100 col-span-2 flex justify-start items-center rounded-tr-lg'>{t('tableHeaderStatusLabel')}</div>
      </div>
      
      { products.map(product => {
        return <CatalogProduct key={product.id} product={product} schemas={schemas} />;
      })}
      <div className='border mb-4 mt-4'></div>
      <TableFooter 
        onChangePage={(number) => handleOnPageChange(number)}
        total={totalProducts}
        page={page}
        availableItemsOnPage={5}
      />
    </>
  );
}

export default ProductsTable;