import { useEffect, useRef, useState } from 'react';
import { Button } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import AddProduct from '../../components/add-product';
import Input from '../../components/input';
import ProductsTable from '../../components/products-table';
import { useDrawer } from '../../providers/drawer-provider';
import { listProductsAsync, listProductsByNameOrBarcodeAsync, listSchemasAsync, selectSchemas, selectTotalProducts } from '../../store/redux/products/products-slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useSearchParams } from 'react-router-dom';
import { SvgAddProducts } from '../../components/svg';
import { useModal } from '../../providers/modal-provider';
import ShareCatalog from '../../components/share-catalog';

const Catalog = () => {
  const drawer = useDrawer();
  const modal = useModal();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const [debounce, setDebounce] = useState<NodeJS.Timeout | null>(null);
  const schemas = useAppSelector(selectSchemas)
  const [searchParams, setSearchParams] = useSearchParams();
  const totalProducts = useAppSelector(selectTotalProducts);
  const refSearch = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const currentPage = searchParams.has('page')
    if (currentPage) {
      setPage(+searchParams.get('page')!);
    } else {
      setSearchParams({
        page: `${page}`
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(listProductsAsync({
      page: page - 1,
      pageSize: 5,
    }))


    dispatch(listSchemasAsync());
    // eslint-disable-next-line
  }, [])

  const handlePageChange = (page: number) => {
    setPage(page);
    if (refSearch.current && refSearch.current.value) {
      dispatch(listProductsByNameOrBarcodeAsync({
        phrase: refSearch.current.value,
        page: page - 1,
        pageSize: 5,
      }))
    } else {
      dispatch(listProductsAsync({
        page: page - 1,
        pageSize: 5,
      }))
    }
    const pageParams: Record<string, string> = {
      page: `${page}`
    }

    if (refSearch.current?.value) {
      pageParams.search = refSearch.current.value
    }

    setSearchParams(pageParams);
  }

  const handleSearchProducts = () => {
    if (debounce) {
      clearTimeout(debounce);
    }

    const timeout = setTimeout(() => {
      if (refSearch.current) {
        const phrase = refSearch.current.value;
        if (phrase) {
          dispatch(listProductsByNameOrBarcodeAsync({
            phrase,
            page: 0,
            pageSize: 5,
          }))
        } else {
          dispatch(listProductsAsync({
            page: 0,
            pageSize: 5,
          }))
        }
        setSearchParams({
          page: '1',
          search: phrase,
        })
        setPage(1);
      }
    }, 1500)

    setDebounce(timeout);
  }

  const handleClickedAddProduct = () => {
    drawer.setIsOpen(true);
    drawer.setTitle(t('addProductTitle'));
    drawer.setComponent(<AddProduct schemas={schemas} />);
  }

  const handleClickedShareCatalog = () => {
    modal.setIsOpen(true);
    modal.setTitle(t('shareCatalogTitle'));
    modal.setComponent(<ShareCatalog />);
  }

  // Empty state
  if (totalProducts < 1 && !searchParams.has('search')) {
    return (
      <div className='w-full h-full flex justify-center flex-col'>
        <div className='w-full flex justify-center'>
          <SvgAddProducts />
        </div>
        <div className='w-full flex justify-center text-sm font-bold mt-2 mb-2'>
          {t('catalogTextEmptyCatalog')}
        </div>
        <div className='w-full flex justify-center text-xs text-gray-500'>
          <span>{t('catalogTextAddSomeProducts')}</span>&nbsp;<span className='text-blue-500 underline'>{t('catalogTextHere')}</span>
        </div>
        <div className='w-full flex justify-center mt-4'>
          <Button
            onClick={handleClickedAddProduct}
            className='normal-case font-normal flex h-8 items-center'
          >
            {t('catalogAddNewProductButtonLabel')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className='h-full flex flex-1 p-4 flex-col'>
      <div className="flex mb-6">
        <div className='flex-1 text-xl'>
          {t('catalogPageTitleLabel')}
        </div>
        <div className='flex'>
          <div className='mr-4'>
            <Button onClick={handleClickedShareCatalog} variant='outlined' className='normal-case font-normal h-8 flex items-center'>
              {t('catalogShareCatalogButtonLabel')}
            </Button>
          </div>
          <div>
            <Button
              onClick={handleClickedAddProduct}
              className='normal-case font-normal flex h-8 items-center'
            >
              {t('catalogAddNewProductButtonLabel')}
            </Button>
          </div>
        </div>
      </div>
      <div className="ml-4">
        <Input
          classes='w-64'
          id="search-catalog-product"
          placeholder='Search product or barcode'
          onChange={handleSearchProducts}
          ref={refSearch}
        />
      </div>
      <ProductsTable page={page} setPage={handlePageChange} />
    </div>
  )
}

export default Catalog;