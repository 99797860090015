import { FC } from 'react';

type Props = {
  label: string | number;
}

const ProductColumnGeneral: FC<Props> = ({ label }) => {
  return (
    <div className='flex content-center'>{label}</div>
  )
}

export default ProductColumnGeneral;