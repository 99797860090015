import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { State, LoginRequest, LoginResponse, RegisterRequest} from './types';
import { fetchIam, fetchLogin, fetchRegister } from './user-api';

const initialState: State = {
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state: State) => {
      state.user = null
    },
    setIamUser: (state: State, action: PayloadAction<LoginResponse>) => {
      state.user = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      // .addCase(signupAsync.fulfilled, (state, action) => {
      //   state.user = action.payload;
      // })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(iamAsync.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        state.user = action.payload;
      })
  },
});

export const loginAsync = createAsyncThunk(
  'user/login',
  async (payload: LoginRequest) => {
    try {
      const response = await fetchLogin(payload);
      // The value we return becomes the `fulfilled` action payload
      return response.json();
    } catch (e) {
      console.log(e);
    }
  }
);


export const iamAsync = createAsyncThunk(
  'user/iam',
  async () => {
    try {
      const response = await fetchIam();
      // The value we return becomes the `fulfilled` action payload
      return response.json();
    } catch (e) {
      console.log(e);
    }
  }
);

export const registerAsync = createAsyncThunk(
  'user/register',
  async (payload: RegisterRequest) => {
    try {
      const response = await fetchRegister(payload);
      // The value we return becomes the `fulfilled` action payload
      return response.json();
    } catch (e) {
      console.log(e);
    }
  }
);



// Reducer
export default userSlice.reducer;
// Actions
export const { logout, setIamUser } = userSlice.actions;

// Selectors
export const selectIam = (state: RootState) => state.iam;
export const selectUserWithError = (state: RootState) => {
  if (!state.iam.user) {
    throw new Error('user is empty');
  }
  return state.iam.user;
};
export const selectUser = (state: RootState) => state.iam.user;

