import { FC } from 'react';

type Props = {
  fill?: string;
}
export const SvgTrash: FC<Props> = ({ fill }) => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.20002 0.600098C5.0515 0.600177 4.90592 0.641604 4.7796 0.71974C4.65329 0.797875 4.55122 0.909633 4.48482 1.0425L3.90562 2.2001H1.20002C0.987851 2.2001 0.784368 2.28438 0.634339 2.43441C0.48431 2.58444 0.400024 2.78792 0.400024 3.0001C0.400024 3.21227 0.48431 3.41575 0.634339 3.56578C0.784368 3.71581 0.987851 3.8001 1.20002 3.8001V11.8001C1.20002 12.2244 1.3686 12.6314 1.66865 12.9315C1.96871 13.2315 2.37568 13.4001 2.80002 13.4001H9.20003C9.62437 13.4001 10.0313 13.2315 10.3314 12.9315C10.6315 12.6314 10.8 12.2244 10.8 11.8001V3.8001C11.0122 3.8001 11.2157 3.71581 11.3657 3.56578C11.5157 3.41575 11.6 3.21227 11.6 3.0001C11.6 2.78792 11.5157 2.58444 11.3657 2.43441C11.2157 2.28438 11.0122 2.2001 10.8 2.2001H8.09443L7.51523 1.0425C7.44883 0.909633 7.34676 0.797875 7.22045 0.71974C7.09413 0.641604 6.94855 0.600177 6.80002 0.600098H5.20002ZM3.60002 5.4001C3.60002 5.18792 3.68431 4.98444 3.83434 4.83441C3.98437 4.68438 4.18785 4.6001 4.40002 4.6001C4.6122 4.6001 4.81568 4.68438 4.96571 4.83441C5.11574 4.98444 5.20002 5.18792 5.20002 5.4001V10.2001C5.20002 10.4123 5.11574 10.6158 4.96571 10.7658C4.81568 10.9158 4.6122 11.0001 4.40002 11.0001C4.18785 11.0001 3.98437 10.9158 3.83434 10.7658C3.68431 10.6158 3.60002 10.4123 3.60002 10.2001V5.4001ZM7.60003 4.6001C7.38785 4.6001 7.18437 4.68438 7.03434 4.83441C6.88431 4.98444 6.80002 5.18792 6.80002 5.4001V10.2001C6.80002 10.4123 6.88431 10.6158 7.03434 10.7658C7.18437 10.9158 7.38785 11.0001 7.60003 11.0001C7.8122 11.0001 8.01568 10.9158 8.16571 10.7658C8.31574 10.6158 8.40003 10.4123 8.40003 10.2001V5.4001C8.40003 5.18792 8.31574 4.98444 8.16571 4.83441C8.01568 4.68438 7.8122 4.6001 7.60003 4.6001Z" fill={fill || '#1F2A37'} />
    </svg>

  )
}