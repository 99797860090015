const en = {
    translation: {
        // Login
        applicationName: 'Nekuda',
        loginSignInTitle: 'Sign in to our platform',
        loginUseNameLabel: 'E-mail',
        loginPasswordLabel: 'Password',
        loginRememberMeLabel: 'Remember Me',
        loginLostPasswordLabel: 'Lost Password?',
        loginContinueButtonLabel: 'Continue',
        loginNotRegisteredText: 'Not Registered?',
        loginCreateAccountText: 'Create an account',

        // Header
        headerLogoutLagel: 'Logout',
        headerProfileLabel: 'My profile',
        headerInvitesLabel: 'Invites',

        // Menu
        menuOrderLabel: 'Orders',
        menuCatalogLabel: 'Catalog',
        menuClientsLabel: 'Clients',
        menuAgentsLabel: 'Agents',
        menuSuppliersLabel: 'Suppliers',

        // Catalog
        catalogPageTitleLabel: 'Catalog',
        catalogShareCatalogButtonLabel: 'Share Catalog',
        catalogAddNewProductButtonLabel: 'Add New Product',
        catalogTextEmptyCatalog: 'Your catalog is empty',
        catalogTextAddSomeProducts: 'Lets add some products or send us your csv',
        catalogTextHere: 'here',

        // Table
        tableShowingLabel: 'Showing',
        tableOutOfLabel: 'out of',

        // Table header
        tableHeaderNameLabel: 'NAME',
        tableHeaderCategoryLabel: 'CATEGORY',
        tableHeaderInventoryAmountLabel: 'INVENTORY AMOUNT',
        tableHeaderStatusLabel: 'STATUS',

        // Table Column Status
        statusLabelPublic: 'Public',
        statusLabelDraft: 'Draft',
        statusLabelOutOfStock: 'Out of stock',

        // Edit Product
        editProductTitle: 'Edit Product',
        editProductNameInputLabel: 'Product Name',
        editProductNameInputPlaceholder: 'Name',
        editProductCategoryInputLabel: 'Product Category',
        editProductCategoryInputPlaceholder: 'Category',
        editProductBarcodeInputLabel: 'Barcode',
        editProductBarcodeInputPlaceholder: 'Barcode',
        editProductInventoryAmountInputLabel: 'Inventory Amount',
        editProductInventoryAmountInputPlaceholder: 'Inventory Amount',
        editProductStatusLabel: 'Status',
        editProductStatusPublicValue: 'Public',
        editProductStatusDraftValue: 'Draft',
        editProductStatusOutOfStockValue: 'Out of stock',
        editProductImageLabel: 'Product Image',
        editProductDeleteImageButtonLabel: 'Delete',
        editProductDeleteProductButtonLabel: 'Delete product',
        editProductUpdateProductButtonLabel: 'Save product',
        editProductToastDeleteItem: 'We successfully deleted',
        editProductToastEditItem: 'was succesfully saved',

        // Add Product
        addProductTitle: 'Add Product',
        addProductNameLabel: 'Product Name',
        addProductCategoryLabel: 'Product Category',
        addProductBarcodeLabel: 'Barcode',
        addProductInventoryAmountLabel: 'Inventory Amount',
        addProductDragInfoText: 'Click to upload or drag and drop',
        addProductDragConstrainsText: 'JPG or PNG (MAX. 800x400px)',
        addProductSelectAdditionalFields: 'None',
        addProductSelectionNone: 'None',
        addProductAddAddtionalFieldSelectionLabel: 'Add new',
        addProductButtonAddLabel: 'Add product',
        addProductToastMissingFields: 'Missing fields, please fill all values',
        addProductToastSuccess: 'Product added succefully',

        // Share catalog
        shareCatalogTitle: 'Share my catalog',
        shareCatalogShareWithText: 'Share your catalog with your clients',
        shareCatalogSendInviteLabel: 'Send invitation by email',
        shareCatalogInputEmail: 'john@doe.com',
        shareCatalogSendButtonLabel: 'Send',
        shareCatalogCopyText: 'Or copy link',
        shareCatalogCopyLink: 'Copy link',
        shareCatalogCopyLinkIndication: 'Catalog link copied to clipboard',
        shareCatalogSendInviteByEmailIndication: 'Catalog was sent succefully',

        // Clients Page
        clientPageTitleLabel: 'Clients',
        clientsSearchClientPlaceholder: 'Search client by name',
        clientAddNewClientButtonLabel: 'Add new client',
        clientTableHeaderNameLabel: 'CLIENT NAME',
        clientTableHeaderPendingOrderLabel: 'PENDING ORDERS',
        clientTableHeaderStatusLabel: 'STATUS',
        clientStatusLabelActive: 'Active',
        clientStatusLabelPending: 'Pending',
        clientStatusLabelSuspended: 'Suspended',

        // View Client
        viewClientTitle: 'View Client',
        viewClientNameInputLabel: 'Name',
        viewClientNameInputPlaceholder: 'Client Name',
        viewClientEmailInputLabel: 'E-Mail',
        viewClientEmailInputPlaceholder: 'Client Email',
        viewClientContactNameInputLabel: 'Contact Name',
        viewClientContactNameInputPlaceholder: 'Contact Name',
        viewClientPhoneNumberInputLabel: 'Phone Number',
        viewClientPhoneNumberInputPlaceholder: 'Phone number',
        viewClientStatusActiveValue: 'Active',
        viewClientStatusPendingValue: 'Pending',
        viewClientStatusSuspendedValue: 'Suspended',
        viewClientDeleteButtonLabel: 'Delete Client',
        viewClientChangeClientStatus: 'Client status changed to',

        // AddClient
        addNewClientTitle: 'Add new client',
        addClientNameInputLabel: 'Name',
        addClientInputNamePlaceholder: 'Name',
        addClientEmailInputLabel: 'Email',
        addClientEmailInputPlaceholder: 'email@company.com',
        addClientContactNameInputLabel: 'Contact Name',
        addClientContactNameInputPlaceholder: 'Contact Name',
        addClientPhoneInputLabel: 'Phone number',
        addClientPhoneInputPlaceholder: '+97255555555',
        addClientInviteContentLabel: 'Invite message',
        addClientInviteContentPlaceholder: `Hi { Name }\nHappy to share with you our\ncatalog, see all of our products\nand send order to our agents`,
        addClientButtonLabel: 'Add client',
        addClientToastSuccess: 'Invite sent succesfully',
        addClientToastMissingFieldsError: 'Some fields are empty, please fill all fields',

        /// Register
        registerTitle: 'Register',
        registerInputNamePlaceholder: 'Name',
        registerInputNameLabel: 'Your name',
        registerInputCompanyNamePlaceholder: 'Company Name',
        registerInputCompanyNameLabel: 'Your company name',
        registerInputEmailPlaceholder: 'john@due.com',
        registerInputEmailLabel: 'Email',
        registerInputPhoneNumberPlaceholder: '+972523123123',
        registerInputPhoneNumberLabel: 'Phone number',
        registerHaveAnAccountText: 'Have an account?',
        registerLoginHereText: 'Login here',
        registerInputPasswordLabel: 'Password',
        registerInputPasswordPlaceholder: '•••••••••••',

        // Footer
        footerCopyrights: '© 2023 Juba. All rights reserved.',

        //Suppliers
        suppliersPageTitleLabel: 'Suppliers',
        suppliersSearchSupplierPlaceholder: 'Search supplier',
        suppliersAddNewSupplierButtonLabel: 'Add new supplier',
        supplierTableHeaderNameLabel: 'Bussines Name',
        supplierTableHeaderPendingOrderLabel: 'Pending orders',
        supplierTableHeaderStatusLabel: 'Status',

        // View Supplier
        viewSupplierbussinesNameInputLabel: 'Bussines name',
        viewSupplierbussinesNamePlaceholder: '',
        viewSupplierEmailInputLabel: 'E-mail',
        viewSupplierEmailPlaceholder: '',
        viewSupplierContactNameInputLabel: 'Contact Name',
        viewSupplierContactNamePlaceholder: '',
        viewSupplerPhoneNumberInputLabel: 'Phone number',
        viewSupplierPhoneNumberPlaceholder: '',
        viewSupplierButtonGoToStoreLabel: 'Go to suppliers store',
    }
}
export default en;