import { ListProductsRequest, AddProductRequest, UpdateProductRequest, ListProductsByNameOrBarcodeRequest } from './types';

export function fetchListProducts(payload: ListProductsRequest) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/products/list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
}

export function fetchAddProduct(payload: AddProductRequest) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/products/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
}

export function fetchListSchemas() {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/schemas/list`, {
    method: 'GET',
    headers: {
    },
    credentials: 'include',
  })
}

export function fetchDeleteProduct(id: string) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/products/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
}

export function fetchUpdateProduct(payload: UpdateProductRequest) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/products`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
}

export function fetchListProductsByNameOrBarcode(payload: ListProductsByNameOrBarcodeRequest) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/products/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
}