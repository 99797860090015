import { Button } from '@material-tailwind/react';
import { ChangeEventHandler, FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDrawer } from '../../providers/drawer-provider';
import { useToast } from '../../providers/toast-provider';
import { useAppDispatch } from '../../store/hooks';
import { deleteProductAsync, listProductsAsync, listProductsByNameOrBarcodeAsync, updateProductAsync } from '../../store/redux/products/products-slice';
import EditProductValueObject from '../../value-objects/edit-product-value-object';
import ProductValueObject, { ProductCustomAttributesShape } from '../../value-objects/product-value-object';
import SchemaValueObject from '../../value-objects/schema-value-object';
import Input from '../input';
import { SvgSuccess, SvgTrash } from '../svg';
import CustomAttributes from './custom-attributes';

type Props = {
  schemas: SchemaValueObject[],
  product: ProductValueObject
}

const EditProduct: FC<Props> = ({ product, schemas }) => {
  const valueObject = new EditProductValueObject({ schemas, product });
  const schema = valueObject.getSchema();
  const [searchParams] = useSearchParams();
  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({})
  const drawer = useDrawer();
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (inputRefs.current?.name) {
      inputRefs.current.name.value = valueObject.getAttributeValue('name', false);
    }

    if (inputRefs.current?.category) {
      inputRefs.current.category.value = valueObject.getAttributeValue('category', false);
    }

    if (inputRefs.current?.barcode) {
      inputRefs.current.barcode.value = valueObject.getAttributeValue('barcode', false);
    }

    const productStatus = valueObject.getAttributeValue('status', false);
    if (inputRefs.current?.statusPublic) {
      inputRefs.current.statusPublic.checked = productStatus === inputRefs.current.statusPublic.value;
    }
    if (inputRefs.current?.statusDraft) {
      inputRefs.current.statusDraft.checked = productStatus === inputRefs.current.statusDraft.value;
    }
    if (inputRefs.current?.statusOutOfStock) {
      inputRefs.current.statusOutOfStock.checked = productStatus === inputRefs.current.statusOutOfStock.value;
    }

    if (inputRefs.current?.inventoryAmount) {
      inputRefs.current.inventoryAmount.value = `${product.inventoryAmount}`;
    }

    schema.getCustomAttributes().forEach(a => {
      if (inputRefs.current && inputRefs.current[a.name]) {
        inputRefs.current[a.name]!.value = valueObject.getAttributeValue(a.name, true).value;

        if (a.options) {
          inputRefs.current[a.name]!.checked = true;
        }
      }
    })
  });

  const handleDeleteProduct = async () => {
    await dispatch(deleteProductAsync(product.id));
    const page = +searchParams.get('page')!;
    const phrase = searchParams.get('search');

    const pagePayload = {
      pageSize: 5,
      page: page - 1,
    }

    if (phrase) {
      await dispatch(listProductsByNameOrBarcodeAsync({
        phrase,
        ...pagePayload
      }));
    } else {
      await dispatch(listProductsAsync(pagePayload))
    }
    drawer.setIsOpen(false);
    toast.openToast(
      `${t('editProductToastDeleteItem')} ${product.name}`,
      'success'
    )
  }

  const handleValueChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const attribute = e.target.name;
    const value = e.target.value;
    valueObject.setDraftAttribute(attribute, value);
  }
  const handleCustomAttributeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const attribute = e.target.name;
    const value = e.target.value;
    
    // TODO: handle undefined
    // @ts-ignore
    let customAttributes: ProductCustomAttributesShape[] = valueObject.getDraft().customAttributes
    if (!customAttributes) {
      valueObject.setDraftAttribute('customAttributes', product.customAttributes);
      customAttributes = product.customAttributes;
    }

    const att = customAttributes.find(c => c.attribute === attribute);
    if (!att) {
      return;
    }
    att.value = value;

    valueObject.setDraftAttribute('customAttributes', customAttributes);
  }

  const handleUpdateProduct = async () => {
    await dispatch(updateProductAsync(valueObject.getDraft()))
    const page = +searchParams.get('page')!;
    const phrase = searchParams.get('search')!;

    const pagePayload = {
      pageSize: 5,
      page: page - 1,
    }

    if (phrase) {
      await dispatch(listProductsByNameOrBarcodeAsync({
        phrase,
        ...pagePayload
      }));
    } else {
      await dispatch(listProductsAsync(pagePayload))
    }
    drawer.setIsOpen(false);
    toast.openToast(`${product.name} ${t('editProductToastEditItem')}`, 'success');
  }

  return (
    <div className='flex'>
      <form className='w-full'>
        <ol className='flex flex-col p-4'>
          <li className='mb-4'>
            <Input
              name='name'
              required
              ref={(ref) => (inputRefs.current.name = ref)}
              label={t('editProductNameInputLabel')}
              classes="w-full"
              id='edit-product-name'
              onChange={handleValueChange}
              placeholder={t('editProductNameInputPlaceholder')}
            />
          </li>
          <li className='mb-4'>
            <Input
              required
              name='category'
              ref={(ref) => (inputRefs.current.category = ref)}
              label={t('editProductCategoryInputLabel')}
              classes="w-full"
              id='edit-product-category'
              onChange={handleValueChange}
              placeholder={t('editProductCategoryInputPlaceholder')}
            />
          </li>
          <li className='mb-4'>
            <Input
              required
              name='barcode'
              ref={(ref) => (inputRefs.current.barcode = ref)}
              label={t('editProductBarcodeInputLabel')}
              classes="w-full"
              id='edit-product-barcode'
              onChange={handleValueChange}
              placeholder={t('editProductBarcodeInputPlaceholder')}
            />
          </li>
          <li className='mb-4'>
            <Input
              required
              name='inventoryAmount'
              ref={(ref) => (inputRefs.current.inventoryAmount = ref)}
              label={t('editProductInventoryAmountInputLabel')}
              classes="w-full" id='edit-product-inventory-amount'
              onChange={handleValueChange}
              placeholder={t('editProductInventoryAmountInputPlaceholder')}
            />
          </li>
          <li className='mb-4'>
            <div className='block mb-2 text-sm font-medium text-gray-900'>
              {t('editProductStatusLabel')}
            </div>
            <span className='text-sm'>
              <span className='mr-2'>
                <input
                  ref={(ref) => (inputRefs.current.statusPublic = ref)}
                  className='border mr-2'
                  id='status-public'
                  name='status'
                  type='radio'
                  value='PUBLIC'
                  onChange={handleValueChange}
                />
                <label htmlFor='status-public'>{t('editProductStatusPublicValue')}</label>
              </span>
              <span className='mr-2'>
                <input
                  ref={(ref) => (inputRefs.current.statusDraft = ref)}
                  className='border mr-2'
                  id='status-draft'
                  name='status'
                  type='radio'
                  value='DRAFT'
                  onChange={handleValueChange}
                />
                <label htmlFor='status-draft'>{t('editProductStatusDraftValue')}</label>
              </span>
              <span className='mr-2'>
                <input
                  ref={(ref) => (inputRefs.current.statusOutOfStock = ref)}
                  className='border mr-2'
                  id='status-out-of-stock'
                  name='status'
                  type='radio'
                  value='OUT_OF_STOCK'
                  onChange={handleValueChange}
                />
                <label htmlFor='status-out-of-stock'>{t('editProductStatusOutOfStockValue')}</label>
              </span>
            </span>
          </li>
          {
            schema.getCustomAttributes().map(a => {
              return <CustomAttributes
                ref={(ref) => (inputRefs.current[a.name] = ref)}
                options={a.options}
                key={a.name}
                id={a.name}
                placeholder={a.name}
                name={a.name}
                label={a.name}
                type={a.type}
                onChange={handleCustomAttributeChange}
              />
            })
          }
          <li className='mb-4'>
            <div>
              <div className='block mb-2 text-sm font-medium text-gray-900'>{t('editProductImageLabel')}</div>
              <div className='flex items-center justify-between'>
                <div>
                  <img className="h-36 mr-2" src={product.thumbnail ? product.thumbnail : process.env.PUBLIC_URL + '/images/product-placeholder.png'} alt="product-thumbnail" />
                </div>
                <div>
                  <Button className='flex items-center normal-case font-normal' color='yellow'>
                    <span className='h-5 w-5 mr-2 flex items-center'>
                      <SvgTrash />
                    </span>
                    {t('editProductDeleteImageButtonLabel')}
                  </Button>
                </div>
              </div>
            </div>
          </li>
          <li className='flex justify-around'>
            <Button onClick={handleUpdateProduct} className='flex items-center normal-case font-normal'>
              <span className='h-5 w-5 mr-2 flex items-center'>
                <SvgSuccess fill='#fff' />
              </span>
              {t('editProductUpdateProductButtonLabel')}
            </Button>
            <Button onClick={handleDeleteProduct} className='flex items-center normal-case font-normal' color='red'>
              <span className='h-5 w-5 mr-2 flex items-center'>
                <SvgTrash fill='#fff' />
              </span>
              {t('editProductDeleteProductButtonLabel')}
            </Button>
          </li>
        </ol>
      </form>
    </div>
  )
}

export default EditProduct;