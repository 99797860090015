import { ChangeEventHandler, forwardRef } from 'react';

type Props = {
    id: string;
    label?: string | null;
    type?: string;
    placeholder: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    required?: boolean;
    classes?: string;
    name?: string;
    disabled?: boolean;
}

const Input= forwardRef<HTMLInputElement, Props>((props, ref) => {
    const {
        id,
        label,
        type = 'text',
        placeholder,
        onChange,
        required = false,
        classes,
        name,
        disabled
    } = props;
    return (
        <>
            {label &&
                <label
                    htmlFor={id}
                    className="block mb-2 text-sm font-medium text-gray-900"
                >
                    {label}
                </label>
            }
            <input
                name={name}
                onChange={onChange}
                type={type}
                id={id}
                className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 bg-gray-50 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${classes}`}
                placeholder={placeholder}
                required={required}
                ref={ref}
                disabled={disabled}
            />
        </>

    )
})

export default Input;