import { Button, Chip } from '@material-tailwind/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../providers/toast-provider';
import Input from '../input';
import { SvgSend } from '../svg';

type Props = {}

const ShareCatalog: FC<Props> = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleSendEmailInvite = () => {
    toast.openToast(t('shareCatalogSendInviteByEmailIndication'), 'success');
  }

  const handleCopyLinkClicked = () => {
    toast.openToast(t('shareCatalogCopyLinkIndication'), 'success');
  }

  return (
    <div className='flex flex-col p-4'>
      <div className='text-xs text-gray-500 mb-2'>{t('shareCatalogShareWithText')}</div>
      <div className='flex flex-col'>
        <div className='mb-2 text-xs'>
          {t('shareCatalogSendInviteLabel')}
        </div>
        <div className='flex mb-5'>
          <span className='flex-1 mr-2'>
            <Input
              id='share-catalog-email'
              placeholder={t('shareCatalogInputEmail')}
              onChange={() => { }}
              name='share-catalog-email'
              classes='w-full pt-2 pb-2'
            />
          </span>
          <span>
            <Button onClick={handleSendEmailInvite} className='items-center normal-case font-normal flex pt-2 pb-2'>
              <span className='mr-2'>
                <SvgSend />
              </span>
              <span>
                {t('shareCatalogSendButtonLabel')}
              </span>
            </Button>
          </span>
        </div>
      </div>
      <div>
        <div className='text-xs mb-2'>{t('shareCatalogCopyText')}</div>
        <div>
          <div className='flex border border-gray-300 text-gray-400 text-sm rounded-lg p-2.5 pb-2 pt-2 bg-gray-50 items-center'>
            <span className='flex-1 text-xs'>
              text
            </span>
            <span onClick={handleCopyLinkClicked}>
              <Chip
                onTap={handleCopyLinkClicked}
                value={t('shareCatalogCopyLink')!}
                color='gray'
                className='normal-case font-normal'
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareCatalog;