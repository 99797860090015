import { RequestListSuppliers } from './types';

export function fetchListSuppliers(payload: RequestListSuppliers) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/suppliers/list`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
}