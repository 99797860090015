import { FC } from 'react';
import { SvgChevron } from '../svg';
import TablePaginationPage from './table-pagination-page';

type Props = {
  page: number;
  totalPages: number;
  onChangePage: (toPage: number) => void;
}

const TablePagination: FC<Props> = ({ totalPages, page, onChangePage }) => {
  const pageNumbers = Array.from(Array(totalPages).keys());
  if (totalPages < 3) {
    return (
      <div className='cursor-pointer'>
        {pageNumbers.map(num => <TablePaginationPage onChangePage={onChangePage} selectedPage={page} key={num} number={num + 1} />)}
      </div>
    )
  }

  let startFrom = 0;
  let endAt = page + 1;

  const enableBackButton = page === 1;
  const enableNextButton = page === totalPages;
  
  if (page > 2) {
    startFrom = page - 2;
  }

  if (page === totalPages) {
    startFrom = page -3;
  }

  if (page < 2) {
    endAt = page + 2;
  }

  const pageNumber = pageNumbers.slice(startFrom, endAt);

  return (
    <div className='flex items-center cursor-pointer'>
      <span onClick={() => onChangePage(page - 1)} className={`border p-2 rotate-180 ${enableBackButton && 'bg-gray-100'}`}><SvgChevron /></span>
      {pageNumber.map(number => <TablePaginationPage onChangePage={onChangePage} selectedPage={page} key={number} number={number + 1} />)}
      {pageNumber[1] + 1 < totalPages -1 ?
          <>
            <span className='border p-2'>...</span>
            <span onClick={() => onChangePage(totalPages)} className='border p-2'>{totalPages}</span>
          </> 
          : null
      }
      <span onClick={() => onChangePage(page + 1)} className={`border p-2 ${enableNextButton && 'bg-gray-100'}`}><SvgChevron /></span>

    </div>
  )
}

export default TablePagination;