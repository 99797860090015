import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import useIamReducer from './redux/user/user-slice';
import useProductsReducer from './redux/products/products-slice';
import useClientsReducer from './redux/clients/clients-slice';
import useInvitesReducer from './redux/invites/invites-slice';
import useSuppliersReducer from './redux/suppliers/suppliers-slice';


export const store = configureStore({
  reducer: {
    iam: useIamReducer,
    products: useProductsReducer,
    clients: useClientsReducer,
    invites: useInvitesReducer,
    suppliers: useSuppliersReducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload'],
        ignoredPaths: ['products', 'clients', 'invites', 'suppliers'],
      }
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

