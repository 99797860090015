import { Checkbox } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import { selectClients, selectInvites } from '../../store/redux/clients/clients-slice';
import ClientItem from '../client-item';

const ClientsTable = () => {
  const { t } = useTranslation();
  const clients = useAppSelector(selectClients);
  const invites = useAppSelector(selectInvites);

  return (
    <>
      <div className='grid grid-cols-12 mt-4 border-b-2 h-10 text-gray-500 text-sm'>
        <div className='bg-slate-100 col-span-1 rounded-tl-lg flex justify-center items-center'><Checkbox /></div>
        <div className='bg-slate-100 col-span-4 flex justify-start items-center'>{t('clientTableHeaderNameLabel')}</div>
        <div className='bg-slate-100 col-span-4 flex justify-start items-center'>{t('clientTableHeaderPendingOrderLabel')}</div>
        <div className='bg-slate-100 col-span-3 flex justify-start items-center'>{t('clientTableHeaderStatusLabel')}</div>
      </div>

      { 
        clients.map(c => <ClientItem key={c.id} client={c} isInvite={false} />)
      }

      {
        invites.map(i => <ClientItem key={i.id} client={i.asClient()} isInvite={true} />)
      }

      <div className='border mb-4 mt-4'></div>
    </>
  )
}

export default ClientsTable;