export type StatusValues = 'PUBLIC' | 'DRAFT' | 'OUT_OF_STOCK';
export type ProductCustomAttributesShape = {
  attribute: string;
  value: string;
}

export type ProductShape = {
  schemaId: string;
  schemaName: string;
  id: string;
  name: string;
  barcode: string;
  category: string;
  inventoryAmount: number;
  status: StatusValues;
  thumbnail: null | string;
  customAttributes: {
    attribute: string;
    value: string;
  }[];
}

class ProductValueObject {
  readonly schemaId: string;
  readonly schemaName: string;
  readonly id: string;
  readonly name: string;
  readonly barcode: string;
  readonly category: string;
  readonly inventoryAmount: number;
  readonly status: StatusValues;
  readonly thumbnail: null | string;
  readonly customAttributes: ProductCustomAttributesShape[];

  constructor(p: ProductShape) {
    this.schemaId = p.schemaId;
    this.schemaName = p.schemaName;
    this.id = p.id;
    this.name = p.name;
    this.barcode = p.barcode;
    this.category = p.category;
    this.inventoryAmount = p.inventoryAmount;
    this.status = p.status;
    this.thumbnail = p.thumbnail;
    this.customAttributes = p.customAttributes;
  }
}

export default ProductValueObject;