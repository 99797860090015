import { ChangeEventHandler, FC } from 'react';
import SchemaValueObject from '../../value-objects/schema-value-object';
import CustomAttributes from '../edit-product/custom-attributes';

type Props = {
  selectedSchema: string | null;
  schemas: SchemaValueObject[];
  onCustomAttributeChange: (attribute: string, value: string) => void;
}

const NewCustomAttributes: FC<Props> = ({ selectedSchema, schemas, onCustomAttributeChange }) => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => { 
    const attribute = e.target.name;
    const value = e.target.value;
    onCustomAttributeChange(attribute, value);
  }

  if (selectedSchema === 'none' || selectedSchema === 'add' || !selectedSchema) {
    return <></>;
  }

  const schema = schemas.find(s => s.getSchemaId() === selectedSchema);
  if (!schema) {
    return <></>;
  }

  return (
    <ol className='mt-4'>
      {schema.getCustomAttributes().map(a => {
        return <CustomAttributes
          key={a.name}
          name={a.name}
          id={a.name}
          onChange={onChange}
          placeholder={a.name}
          options={a.options}
          type={a.type}
        />
      })}
    </ol>
  )
}

export default NewCustomAttributes;