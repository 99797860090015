export const SvgAddProducts = () => {
  return (
    <svg width="155" height="139" viewBox="0 0 155 139" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M78.4251 137.973C108.512 137.973 133.027 113.873 133.027 84.2726C133.027 54.5726 108.613 30.5726 78.4251 30.5726C48.237 30.4726 23.8232 54.4726 23.8232 84.1726C23.8232 113.873 48.237 137.973 78.4251 137.973Z" fill="#F1F3F9" stroke="#D6DCE8" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M68.8014 65.4727L67.3832 61.5727C67.0793 60.5727 66.0663 59.9727 65.0533 59.9727H40.1329C38.0056 59.9727 36.3848 61.5727 36.3848 63.6727V118.773V109.673H121.479V70.7727C121.479 68.6727 119.858 67.0727 117.73 67.0727H71.1314C70.1184 67.0727 69.2066 66.4727 68.8014 65.4727Z" fill="#F1F3F9" stroke="#D6DCE8" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M38.8161 33.6728L55.8348 62.8728C56.3413 63.8728 56.0374 65.1729 55.0244 65.6729L26.052 81.6729C25.039 82.1729 23.7221 81.8729 23.2156 80.8729L1.84081 43.0729C1.1317 42.0729 1.63821 40.7729 2.65123 40.2729L23.7221 28.6729L38.8161 33.6728Z" fill="white" stroke="#D6DCE8" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M23.5195 36.7729L9.53983 44.4729C9.23592 44.6729 8.72941 44.5729 8.62811 44.1729C8.42551 43.8729 8.52681 43.3729 8.83072 43.2729L22.8104 35.6729C23.1143 35.4729 23.6208 35.5729 23.7221 35.8729C23.9247 36.1729 23.8234 36.6729 23.5195 36.7729Z" fill="#D6DCE8" />
      <path d="M16.7321 44.8728L11.3631 47.7728C11.0592 47.9728 10.654 47.8728 10.5527 47.5728C10.3501 47.2728 10.4514 46.7728 10.7553 46.6728L16.023 43.7728C16.3269 43.5728 16.8334 43.6728 16.9347 43.9728C17.2386 44.1728 17.1373 44.6728 16.7321 44.8728Z" fill="#D6DCE8" />
      <path d="M23.7222 28.6729L28.3821 36.7729C29.0912 37.8729 30.6107 38.2728 31.725 37.6728L39.1201 33.6728" fill="#D6DCE8" />
      <path d="M40.5382 51.4729C39.6264 49.673 38.4108 47.5729 36.8913 46.0729C36.0809 45.2729 35.0679 44.7729 33.8522 44.7729C32.6366 44.7729 31.5223 45.1729 30.3067 45.7729C28.1793 46.6729 26.2546 47.5729 24.3298 48.7729C22.6077 49.7729 20.5817 50.8729 19.2647 52.4729C17.5426 54.7729 18.4543 57.0729 19.6699 59.4729C20.5817 61.2729 21.7973 63.373 23.3168 64.873C24.5324 66.073 26.1533 66.4729 27.7741 65.9729C29.9014 65.4729 33.8522 63.2729 35.6757 62.1729C37.2965 61.1729 39.2212 60.2729 40.4369 58.7729C42.7668 56.4729 41.9564 54.0729 40.5382 51.4729ZM26.3559 53.9729C26.052 53.3729 26.2546 52.8729 27.065 52.9729C29.1923 53.0729 31.2184 53.0729 33.2444 53.1729C34.0548 53.1729 34.2574 53.5729 33.9535 54.2729C32.9405 55.9729 31.9275 57.7729 30.9145 59.5729C30.5093 60.1729 30.0027 60.1729 29.6988 59.6729C29.3949 59.3729 26.6598 54.4729 26.3559 53.9729Z" fill="#D6DCE8" />
      <path d="M103.244 84.6728L102.332 84.0728C102.13 83.8728 102.13 83.6728 102.332 83.4728C102.535 83.2728 102.738 83.2728 103.041 83.3728L103.953 83.9728C104.156 84.0728 104.156 84.3728 104.055 84.5728C103.751 84.7728 103.447 84.7728 103.244 84.6728Z" fill="#E3EAF2" />
      <path d="M100.813 82.7728L95.6466 78.7728C95.444 78.6728 95.444 78.3728 95.5453 78.1728C95.6466 77.9728 95.9505 77.9728 96.2544 78.0728L101.421 82.0728C101.623 82.1728 101.623 82.4728 101.522 82.6728C101.32 82.8728 101.016 82.9728 100.813 82.7728Z" fill="#E3EAF2" />
      <path d="M153.389 57.0727L134.04 81.7727C133.432 82.6727 132.217 82.7727 131.305 82.0727L107.195 63.3727C106.283 62.7727 106.182 61.5727 106.891 60.6727L132.014 29.1727C132.622 28.2727 133.837 28.1727 134.749 28.8727L152.274 42.4727L153.389 57.0727Z" fill="white" stroke="#D6DCE8" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M145.183 45.1727L133.534 36.1727C133.331 35.9727 133.23 35.6727 133.432 35.2727C133.635 35.0727 133.939 34.9727 134.344 35.1727L145.994 44.1727C146.196 44.3727 146.298 44.6727 146.095 45.0727C145.791 45.3727 145.487 45.3727 145.183 45.1727Z" fill="#D6DCE8" />
      <path d="M135.864 42.3728L131.508 38.8728C131.305 38.6728 131.204 38.3728 131.406 37.9728C131.609 37.6728 131.913 37.6728 132.318 37.8728L136.674 41.3728C136.877 41.5728 136.978 41.8728 136.775 42.2728C136.471 42.5728 136.066 42.5728 135.864 42.3728Z" fill="#D6DCE8" />
      <path d="M152.274 42.4727L146.905 49.1727C146.095 50.1727 146.399 51.5727 147.311 52.2727L153.49 57.0727" fill="#D6DCE8" />
      <path d="M145.892 58.5728L139.814 53.9728L133.534 49.0728L127.759 44.5728C127.152 44.0728 126.139 44.1728 125.632 44.8728L117.325 55.2728C116.819 55.8728 116.92 56.8728 117.528 57.3728L135.762 71.5728C136.37 72.0728 137.383 71.9728 137.89 71.2728L146.196 60.8728C146.703 59.9728 146.602 59.1728 145.892 58.5728ZM126.645 58.4728L128.469 56.0728L134.04 60.3728L132.217 62.7728L126.645 58.4728ZM131.71 63.4728L129.988 65.6728L124.416 61.3728L126.139 59.1728L131.71 63.4728ZM136.471 57.3728L134.648 59.7728L129.076 55.4728L130.9 53.0728L136.471 57.3728ZM131.406 52.3728L133.432 49.9728L139.004 54.2728L137.18 56.6728L131.406 52.3728ZM141.536 65.0728L135.357 60.2728L137.18 57.8728L143.36 62.6728L141.536 65.0728ZM128.367 54.8728L122.39 50.2728L124.214 47.8728L130.191 52.4728L128.367 54.8728ZM121.783 50.9728L127.759 55.5728L125.936 57.9728L119.959 53.3728L121.783 50.9728ZM134.851 60.9728L141.03 65.7728L139.207 68.1728L133.027 63.3728L134.851 60.9728ZM145.487 60.1728L143.968 62.0728L137.788 57.2728L139.612 54.8728L145.285 59.2728C145.69 59.4728 145.69 59.8728 145.487 60.1728ZM127.152 45.0728L132.622 49.3728L130.798 51.7728L124.822 47.1728L126.341 45.2728C126.544 44.9728 126.949 44.9728 127.152 45.0728ZM118.034 55.6728L119.351 54.0728L125.328 58.6728L123.606 60.8728L118.034 56.5728C117.832 56.3728 117.832 55.8728 118.034 55.6728ZM136.37 70.5728L130.697 66.1728L132.419 63.9728L138.599 68.7728L137.18 70.4728C136.978 70.8728 136.573 70.8728 136.37 70.5728Z" fill="#D5DDEA" />
      <path d="M96.0517 80.8726L90.2775 80.2726C89.9736 80.2726 89.771 79.9726 89.8723 79.6726C89.8723 79.3726 90.1762 79.1726 90.5814 79.2726L96.3556 79.8726C96.6595 79.8726 96.8621 80.1726 96.7608 80.4726C96.6595 80.7726 96.3556 81.0726 96.0517 80.8726Z" fill="url(#paint0_linear_281_17079)" />
      <path d="M123.201 86.2727L116.92 113.973C116.515 115.573 114.59 116.873 112.159 116.873H43.9825C41.6525 116.873 39.6265 115.673 39.2213 113.973L34.0549 91.2727C33.6496 89.5727 35.5744 87.9727 38.0056 87.9727H76.5004C77.7161 87.9727 78.6278 87.3727 79.033 86.6727L80.1473 84.2727C80.5525 83.4727 81.5655 82.9727 82.6799 82.9727H119.351C121.681 82.9727 123.505 84.5727 123.201 86.2727Z" fill="white" />
      <path d="M128.164 82.5729L121.175 125.073C120.769 127.573 118.541 129.473 115.907 129.473H39.9303C37.2964 129.473 35.0678 127.673 34.6626 125.073L28.8884 90.1729C28.4832 87.5729 30.5092 85.0729 33.2444 85.0729H76.0951C77.4121 85.0729 78.5264 84.1729 78.9316 82.9729L80.1472 79.2729C80.5524 78.0728 81.6668 77.1729 82.9837 77.1729H123.808C126.442 77.4729 128.57 79.8729 128.164 82.5729Z" fill="white" stroke="#D6DCE8" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M105.068 26.9728L101.826 61.6728C101.725 62.8728 100.61 63.7728 99.3947 63.5728L65.1546 60.0728C63.939 59.9728 63.0272 58.8728 63.1285 57.6728L67.7884 13.1728C67.8897 11.9728 69.0041 11.0728 70.2197 11.2728L95.0387 13.7728L105.068 26.9728Z" fill="white" stroke="#D6DCE8" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M95.1401 13.8728L94.1271 23.3728C94.0258 24.6728 95.0388 25.9728 96.457 26.0728L105.169 26.9728" fill="#D6DCE8" />
      <path d="M98.3817 52.2728L70.3211 49.1728C69.612 49.0728 69.1055 48.5728 69.2068 47.8728L71.3341 30.1728C71.4354 29.5728 72.0432 28.9728 72.651 29.0728L100.712 32.0728C101.421 32.1728 101.927 32.6728 101.826 33.3728L99.6987 51.0728C99.5974 51.7728 98.9896 52.2728 98.3817 52.2728Z" fill="#F1F3F9" />
      <path d="M85.7191 47.9727L72.7524 46.4727L80.0462 39.9727L85.7191 47.9727Z" fill="#D6DCE8" />
      <path d="M96.4569 49.1726L77.7161 46.9726L88.3528 37.6726L96.4569 49.1726Z" fill="white" />
      <path d="M79.033 37.1726C80.4513 37.1726 81.5656 36.0726 81.5656 34.6726C81.5656 33.2726 80.4513 32.1726 79.033 32.1726C77.6148 32.1726 76.5005 33.2726 76.5005 34.6726C76.5005 36.0726 77.6148 37.1726 79.033 37.1726Z" fill="#D6DCE8" />
      <path d="M89.8723 19.6728L72.8536 17.7728C72.651 17.7728 72.4483 17.5728 72.5496 17.3728V17.1728C72.5496 16.9728 72.7523 16.7728 72.9549 16.8728L89.9736 18.7728C90.1762 18.7728 90.3788 18.9728 90.2775 19.1728V19.3728C90.3788 19.5728 90.1762 19.7728 89.8723 19.6728Z" fill="#D6DCE8" />
      <path d="M89.7712 22.8727L72.5498 20.9727C72.3472 20.9727 72.1446 20.7727 72.2459 20.5727V20.3727C72.2459 20.1727 72.4485 19.9727 72.6511 20.0727L89.8725 21.9727C90.0751 21.9727 90.2777 22.1727 90.1764 22.3727V22.5727C90.2777 22.6727 89.9738 22.8727 89.7712 22.8727Z" fill="#D6DCE8" />
      <path d="M90.3788 55.5727L69.0041 53.1727C68.7002 53.1727 68.4976 52.8727 68.5989 52.5727C68.5989 52.2727 68.9028 52.0727 69.308 52.1727L90.5814 54.5727C90.8853 54.5727 91.0879 54.8727 90.9866 55.1727C90.8853 55.4727 90.6827 55.6727 90.3788 55.5727Z" fill="#D6DCE8" />
      <path d="M87.2385 25.8727L72.2458 24.1727C71.9419 24.1727 71.7393 23.8727 71.8406 23.5727C71.8406 23.2727 72.1445 23.0727 72.5497 23.1727L87.5424 24.8727C87.8463 24.8727 88.0489 25.1727 87.9476 25.4727C87.8463 25.6727 87.6437 25.8727 87.2385 25.8727Z" fill="#D6DCE8" />
      <path d="M98.2805 59.6727L68.4977 56.1727C68.1938 56.1727 67.9912 55.8727 68.0925 55.5727C68.0925 55.2727 68.3964 55.0727 68.8016 55.1727L98.5844 58.5727C98.8884 58.5727 99.091 58.8727 98.9896 59.1727C98.8883 59.4727 98.5844 59.6727 98.2805 59.6727Z" fill="#D6DCE8" />
      <path d="M67.3835 6.6727C66.9782 6.8727 66.4717 6.6727 66.2691 6.2727L64.4457 1.7727C64.2431 1.3727 64.4457 0.872699 64.8509 0.672699C65.2561 0.472699 65.7626 0.672699 65.9652 1.0727L67.7887 5.5727C68.0926 6.1727 67.89 6.5727 67.3835 6.6727Z" fill="#D6DCE8" />
      <path d="M64.0404 11.0727C63.8378 11.2727 63.6352 11.3727 63.23 11.2727L58.4688 10.3727C58.0635 10.2727 57.6583 9.87268 57.7596 9.37268C57.8609 8.97268 58.2662 8.57268 58.7727 8.77268L63.5339 9.67268C63.9391 9.77268 64.3443 10.1727 64.243 10.6727C64.1417 10.7727 64.1417 10.9727 64.0404 11.0727Z" fill="#D6DCE8" />
      <path d="M73.6641 6.17264C73.5628 6.17264 73.4615 6.07264 73.2589 6.07264C72.955 5.77264 72.8537 5.27264 73.1576 4.87264L76.0954 1.07264C76.3993 0.772643 76.9058 0.672643 77.311 0.972643C77.6149 1.27264 77.7162 1.77264 77.4123 2.17264L74.4745 5.97264C74.1706 6.07264 73.8667 6.17264 73.6641 6.17264Z" fill="#D6DCE8" />
      <path d="M98.8881 122.773C98.4829 124.473 97.8751 126.273 97.166 127.773C95.2412 131.473 92.2022 134.373 88.454 136.273C84.6045 138.173 80.0459 138.973 75.4873 137.973C64.7493 135.773 57.8608 125.373 60.0894 114.773C62.3181 104.173 72.7522 97.2728 83.4902 99.5728C87.3397 100.373 90.6826 102.273 93.5191 104.873C98.2803 109.573 100.306 116.373 98.8881 122.773Z" fill="#D6DCE8" stroke="#AAB2C5" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M85.8202 116.073H82.2616V112.573C82.2616 111.147 81.1309 109.873 79.5395 109.873C78.1268 109.873 76.8174 110.971 76.8174 112.573V116.073H73.2588C71.8461 116.073 70.5366 117.171 70.5366 118.773C70.5366 119.516 70.8047 120.205 71.3153 120.709C71.825 121.213 72.5167 121.473 73.2588 121.473H76.8174V124.973C76.8174 126.399 77.9481 127.673 79.5395 127.673C80.9522 127.673 82.2616 126.575 82.2616 124.973V121.473H85.8202C87.2329 121.473 88.5424 120.375 88.5424 118.773C88.5424 117.171 87.2329 116.073 85.8202 116.073Z" fill="white" stroke="#AAB2C5" strokeWidth="2" />
      <defs>
        <linearGradient id="paint0_linear_281_17079" x1="89.7396" y1="79.6546" x2="96.6511" y2="80.462" gradientUnits="userSpaceOnUse">
          <stop stopColor="#83A6FF" />
          <stop offset="1" stopColor="#5A78FF" />
        </linearGradient>
      </defs>
    </svg>

  )
}