import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import InviteValueObject, { InviteShape } from '../../../value-objects/invites-value-object';
import { RootState } from '../../store';
import { fetchListInvites } from './invites-api';
import { State } from './types';

const initialState: State = {
  invites: [],
  total: 0,
};

export const invitesSlice = createSlice({
  name: 'invites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(signupAsync.fulfilled, (state, action) => {
      //   state.user = action.payload;
      // })
      .addCase(listInvitesAsync.fulfilled, (state, action) => {
        state.total = action.payload.total;
        state.invites = action.payload.invites.map((i: InviteShape) => new InviteValueObject(i));
      })
  },
});

export const listInvitesAsync = createAsyncThunk(
  'invites/list',
  async () => {
    try {
      const response = await fetchListInvites();
      return response.json();
    } catch (e) {
      console.log(e);
    }
  }
);

// Reducer
export default invitesSlice.reducer;

// Selectors
export const selectInvitesTotal = (state: RootState) => state.invites.total;