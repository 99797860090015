import React, { FunctionComponent, PropsWithChildren, useContext, useState } from 'react';

interface ToastContextProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  color: 'success' | 'error';
  setColor: (color: 'success' | 'error') => void;
  text: string;
  setText: (text: string) => void;
  openToast: (text: string, color: Color) => void;
}

type Color = 'success' | 'error';

export const ToastContext = React.createContext<ToastContextProps>(undefined!);

const ToastProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [color, setColor] = useState<Color>('success');
  const [text, setText] = useState<string>('');

  const openToast = (text: string, color: Color) => {
    setIsOpen(true);
    setText(text);
    setColor(color);

    setTimeout(() => {
      setIsOpen(false);
    }, 5000)
  }

  return (
    <ToastContext.Provider value={{
      setIsOpen,
      isOpen,
      color,
      setColor,
      text,
      setText,
      openToast,
    }}>
      {children}
    </ToastContext.Provider>
  );
};

// Custom context hook
const useToast = () => {
  return useContext(ToastContext);
};

export { ToastProvider, useToast };
