import { FC, ComponentType, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  path: string;
  label: string;
  SvgIcon: ComponentType;
}

const NavigationItem: FC<Props> = ({label, SvgIcon, path }) => {
  
  const [classes, setClasses] = useState<string>('');
  const location = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    let className = 'flex p-1 mb-4 rounded cursor-pointer'
    if (path.startsWith(location.pathname)) {
      className += ' bg-gray-100'
    }
    setClasses(className);
  }, [location.pathname, path])

  const handleMenuItemClicked = () => {
    navigation(path);
  }

  return (
    <li className={classes} onClick={handleMenuItemClicked}>
      <span className='mr-2'>
        <SvgIcon />
      </span>
      <span>
        {label}
      </span>
    </li>
  )
}

export default NavigationItem;