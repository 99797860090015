import LoginView from '../../components/login-view';

const Login = () => {
    return (
        <div className='bg-blue-900 min-h-screen min-w-screen flex justify-center items-center flex-1'>
            <LoginView />
        </div>
    )
}

export default Login;