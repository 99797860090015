import { Button } from '@material-tailwind/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddClient from '../../components/add-client';
import ClientsTable from '../../components/clients-table';
import Input from '../../components/input';
import { useDrawer } from '../../providers/drawer-provider';
import { useAppDispatch } from '../../store/hooks';
import { listClientsAsync } from '../../store/redux/clients/clients-slice';

const Clients = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listClientsAsync());
  }, [dispatch]);

  const handleAddNewClientClicked = () => {
    drawer.setIsOpen(true);
    drawer.setTitle(t('addNewClientTitle'));
    drawer.setComponent(<AddClient />)
  }

  return (
    <div className='h-full flex flex-1 p-4 flex-col'>
      <div className="flex mb-6">
        <div className='flex-1 text-xl'>
          {t('clientPageTitleLabel')}
        </div>
        <div className='flex'>
          <div>
            <Button
              onClick={handleAddNewClientClicked}
              className='normal-case font-normal flex h-8 items-center'
            >
              {t('clientAddNewClientButtonLabel')}
            </Button>
          </div>
        </div>
      </div>
      <div className="ml-4">
        <Input
          classes='w-64'
          id="search-client"
          placeholder={t('clientsSearchClientPlaceholder')}
          onChange={() => { }}
        />
      </div>
      <ClientsTable />
    </div>
  )
}

export default Clients;