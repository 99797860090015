import { Button } from '@material-tailwind/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '../../providers/drawer-provider';
import { useToast } from '../../providers/toast-provider';
import { useAppDispatch } from '../../store/hooks';
import { addNewClientsAsync, listClientsAsync } from '../../store/redux/clients/clients-slice';
import Input from '../input';
import TextArea from '../text-area';

const AddClient = () => {
  const drawer = useDrawer();
  const toast = useToast();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const inputRefs = useRef<Record<string, HTMLInputElement | HTMLTextAreaElement | null>>({});

  const handleAddNewClientClicked = async () => {
    const bussinesName = inputRefs.current.bussinesName?.value;
    const email = inputRefs.current.email?.value;
    const contactName = inputRefs.current.contactName?.value;
    const phone = inputRefs.current.phone?.value;
    const message = inputRefs.current.message?.value;

    if (!bussinesName || !email || !contactName || !phone || !message) {
      toast.openToast(t('addClientToastMissingFieldsError'), 'error');
      return
    }

    await dispatch(addNewClientsAsync({
      bussinesName,
      email,
      contactName,
      phone,
      message,
    }));

    await dispatch(listClientsAsync());
    drawer.setIsOpen(false);
    toast.openToast(t('addClientToastSuccess'), 'success');
  }

  return (
    <div className='h-full flex flex-col'>
      <div className='flex-1'>

        <ol className='flex flex-col p-4'>
          <li className='mb-6'>
            <Input
              ref={(ref) => (inputRefs.current.bussinesName = ref)}
              name="name"
              label={t('addClientNameInputLabel')}
              classes='w-full'
              id='add-client-name'
              placeholder={t('addClientInputNamePlaceholder')}
            />
          </li>
          <li className='mb-6'>
            <Input
              ref={(ref) => (inputRefs.current.email = ref)}
              name="email"
              label={t('addClientEmailInputLabel')}
              classes='w-full'
              id='add-client-email'
              onChange={() => { }}
              placeholder={t('addClientEmailInputPlaceholder')}
            />
          </li>
          <li className='mb-6'>
            <Input
              ref={(ref) => (inputRefs.current.contactName = ref)}
              name="contactName"
              label={t('addClientContactNameInputLabel')}
              classes='w-full'
              id='add-client-contactName'
              onChange={() => { }}
              placeholder={t('addClientContactNameInputPlaceholder')}
            />
          </li>
          <li className='mb-6'>
            <Input
              ref={(ref) => (inputRefs.current.phone = ref)}
              name="phoneNumber"
              label={t('addClientPhoneInputLabel')}
              classes='w-full'
              id='add-client-phone'
              onChange={() => { }}
              placeholder={t('addClientPhoneInputPlaceholder')}
            />
          </li>
          <li className='mb-6'>
            <TextArea
              ref={(ref) => (inputRefs.current.message = ref)}
              label={t('addClientInviteContentLabel')}
              placeholder={t('addClientInviteContentPlaceholder')}
              classes='w-full'
              name="inviteContent"
              id='add-client-invite-content'
              onChange={() => { }}
              rows={6}
            />
          </li>
          <Button
            onClick={handleAddNewClientClicked}
            className='normal-case font-normal'
          >
            {t('addClientButtonLabel')}
          </Button>
        </ol>
      </div>
    </div>
  )
}

export default AddClient;