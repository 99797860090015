import ClientValueObject from './client-value-object';

export type InviteShape = {
  bussinesName: string;
  contactName: string;
  email: string;
  id: string;
  message: string;
  phone: string;
}

class InviteValueObject {
  readonly bussinesName: string;
  readonly contactName: string;
  readonly email: string;
  readonly id: string;
  readonly message: string;
  readonly phone: string;

  constructor(i: InviteShape) {
    this.bussinesName = i.bussinesName
    this.contactName = i.contactName
    this.email = i.email
    this.id = i.id
    this.message = i.message
    this.phone = i.phone
  }

  asClient(): ClientValueObject {
    return new ClientValueObject({
      bussinesName: this.bussinesName,
      contactName: this.contactName,
      email: this.email,
      id: this.id,
      status: 'PENDING',
      phone: this.phone,
    });
  }
}

export default InviteValueObject;