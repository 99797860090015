import { Button } from '@material-tailwind/react';
import { MouseEvent, FormEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { loginAsync, registerAsync } from '../../store/redux/user/user-slice';
import Input from '../input';

const RegisterView = () => {
  const { t } = useTranslation();
  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({});
  const disptach = useAppDispatch();
  const navigation = useNavigate()

  const handleOnSubmit = async (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    const name = inputRefs.current.contactName?.value;
    const bussinesName = inputRefs.current.companyName?.value;
    const email = inputRefs.current.email?.value;
    const phone = inputRefs.current.phoneNumber?.value;
    const password = inputRefs.current.password?.value;
    if (!name || !bussinesName || !email || !phone || !password) {
      // TODO: empty field handle error
      return;
    }
    await disptach(registerAsync({
      email,
      password,
      name,
      bussinesName,
      phone
    }));

    await disptach(loginAsync({
      username: email,
      password,
    }));

    navigation('/');

  }

  return (
    <div className='md:w-1/4 w-3/4 bg-white flex min-h-96 p-8 pt-0 flex-col rounded'>
      <div className="text-4xl mb-6 pt-6">
        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt='logo'></img>
      </div>
      <div className="text-xl mb-6">
        {t('registerTitle')}
      </div>
      <form onSubmit={handleOnSubmit}>
        <div>
          <div className='mb-6'>
            <div className="mb-6">
              <Input
                ref={(ref) => (inputRefs.current.contactName = ref)}
                id='name'
                placeholder={t('registerInputNamePlaceholder')}
                label={t('registerInputNameLabel')}
                classes="w-full"
                required
              />
            </div>
            <div className="mb-6">
              <Input
                ref={(ref) => (inputRefs.current.companyName = ref)}
                id='companyName'
                placeholder={t('registerInputCompanyNamePlaceholder')}
                label={t('registerInputCompanyNameLabel')}
                classes="w-full"
                required
              />
            </div>
            <div className="mb-6">
              <Input
                ref={(ref) => (inputRefs.current.email = ref)}
                id='email'
                placeholder={t('registerInputEmailPlaceholder')}
                label={t('registerInputEmailLabel')}
                classes="w-full"
                required
              />
            </div>
            <div className="mb-6">
              <Input
                ref={(ref) => (inputRefs.current.phoneNumber = ref)}
                id='phoneNumber'
                placeholder={t('registerInputPhoneNumberPlaceholder')}
                label={t('registerInputPhoneNumberLabel')}
                classes="w-full"
                required
              />
            </div>
            <div className="mb-6">
              <Input
                ref={(ref) => (inputRefs.current.password = ref)}
                id='password'
                type='password'
                placeholder={t('registerInputPasswordPlaceholder')}
                label={t('registerInputPasswordLabel')}
                classes="w-full"
                required
              />
            </div>
          </div>
          <div>
            <Button onClick={handleOnSubmit} className="normal-case" type='submit' fullWidth>{t('loginContinueButtonLabel')}</Button>
          </div>
          <div className='mt-4'>
            <span className="text-gray-600">
              {t('registerHaveAnAccountText')}
            </span>
            <span className="text-blue-600 ml-1">
              <Link to="/login">{t('registerLoginHereText')}</Link>
            </span>
          </div>
        </div>
      </form >
    </div >
  )
}

export default RegisterView;