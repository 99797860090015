export type CustomAttributes = {
  order: number;
  name: string
  type: 'text' | 'radio' | 'selection' | 'checkbox'
  options?: string[]
}

export type SchemaShape = {
  name: string,
  id: string,
  customAttributes: CustomAttributes[];
}

const DEFAULT_SCHEMA_NAME = 'default';
const DEFAULT_SCHEMA_ID = 'default';
class SchemaValueObject {
  private schemaName: string;
  private schemaId: string;
  private customAttributes: CustomAttributes[];


  constructor(schema?: SchemaShape) {
    this.schemaName = schema?.name || DEFAULT_SCHEMA_NAME
    this.schemaId = schema?.id || DEFAULT_SCHEMA_ID 
    this.customAttributes = schema?.customAttributes || [];
  };

  getCustomAttributes() {
    return this.customAttributes;
  }

  getSchemaName() {
    return this.schemaName;
  }

  getSchemaId() {
    return this.schemaId;
  }
}

export default SchemaValueObject;