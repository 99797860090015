import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ClientValueObject, { ClientShape } from '../../../value-objects/client-value-object';
import InviteValueObject, { InviteShape } from '../../../value-objects/invites-value-object';
import { RootState } from '../../store';
import { fetchAddNewClient, fetchChangeClientStatus, fetchListClients } from './clients-api';
import { RequestAddNewClientShape, RequestChangeClientStatusShape, State } from './types';

const initialState: State = {
  clients: [],
  invites: [],
  totalClients: 0,
  totalInvites: 0,
};

export const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder
      .addCase(listClientsAsync.fulfilled, (state, action) => {
        state.clients = action.payload.clients.map((c: ClientShape) => new ClientValueObject(c));
        state.invites = action.payload.invites.map((i: InviteShape) => new InviteValueObject(i));
      })
  },
});

export const listClientsAsync = createAsyncThunk(
  'clients/list',
  async () => {
    try {
      const response = await fetchListClients();
      const json = await response.json();
      return json;
    } catch (e) {
      console.log(e);
    }
  }
);

export const addNewClientsAsync = createAsyncThunk(
  'clients/add-new-client',
  async (payload: RequestAddNewClientShape) => {
    try {
      const response = await fetchAddNewClient(payload);
      const json = await response.json();
      return json;
    } catch (e) {
      console.log(e);
    }
  }
);

export const clientStatusChangeAsync = createAsyncThunk(
  'clients/status-change',
  async (payload: RequestChangeClientStatusShape) => {
    try {
      const response = await fetchChangeClientStatus(payload);
      const json = await response.json();
      return json;
    } catch (e) {
      console.log(e);
    }
  }
);

export default slice.reducer;

export const selectClients = (state: RootState) => state.clients.clients;
export const selectInvites = (state: RootState) => state.clients.invites;