import { Button } from '@material-tailwind/react';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SupplierValueObject from '../../value-objects/supplier-value-object';
import Input from '../input';

type Props = {
  supplier: SupplierValueObject;
}

const ViewSupplier: FC<Props> = ({ supplier }) => {
  const { t } = useTranslation();
  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({})

  useEffect(() => {
    if (inputRefs.current.bussinesName) {
      inputRefs.current.bussinesName.value = supplier.bussinesName;
    }

    if (inputRefs.current.email) {
      inputRefs.current.email.value = supplier.supplierEmail;
    }

    if (inputRefs.current.contactName) {
      inputRefs.current.contactName.value = supplier.supplierContactName;
    }

    if (inputRefs.current.phoneNumber) {
      inputRefs.current.phoneNumber.value = supplier.phone;
    }
  }, [supplier])

  return (
    <div className='h-full flex flex-col'>
      <div className='flex-1'>
        <div className='w-full'>
          <ol className='flex flex-col p-4'>
            <li className='mb-4'>
              <Input
                name='bussinesName'
                ref={(ref) => (inputRefs.current.bussinesName = ref)}
                label={t('viewSupplierbussinesNameInputLabel')}
                classes="w-full"
                id='view-supplier-bussines-name'
                placeholder={t('viewSupplierbussinesNamePlaceholder')}
                disabled
              />
            </li>
            <li className='mb-4'>
              <Input
                name='emil'
                ref={(ref) => (inputRefs.current.email = ref)}
                label={t('viewSupplierEmailInputLabel')}
                classes="w-full"
                id='view-supplier-email'
                placeholder={t('viewSupplierEmailPlaceholder')}
                disabled
              />
            </li>
            <li className='mb-4'>
              <Input
                name='contactName'
                ref={(ref) => (inputRefs.current.contactName = ref)}
                label={t('viewSupplierContactNameInputLabel')}
                classes="w-full"
                id='view-supplier-contact-name'
                placeholder={t('viewSupplierContactNamePlaceholder')}
                disabled
              />
            </li>
            <li className='mb-4'>
              <Input
                name='phoneNumber'
                ref={(ref) => (inputRefs.current.phoneNumber = ref)}
                label={t('viewSupplerPhoneNumberInputLabel')}
                classes="w-full"
                id='view-supplier-phone-number'
                placeholder={t('viewSupplierPhoneNumberPlaceholder')}
                disabled
              />
            </li>
            <li>
              <Button className='normal-case font-normal' fullWidth>{t('viewSupplierButtonGoToStoreLabel')}</Button>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default ViewSupplier;