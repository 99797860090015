import { Checkbox } from '@material-tailwind/react';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '../../providers/drawer-provider';
import ProductValueObject from '../../value-objects/product-value-object';
import SchemaValueObject from '../../value-objects/schema-value-object';
import EditProduct from '../edit-product';
import ProductColumnStatus from './product-colum-status';
import ProductColumnGeneral from './product-column-general';
import ProductColumnName from './product-column-name';

type Props = {
  schemas: SchemaValueObject[],
  product: ProductValueObject,
}

const CatalogProduct: FC<Props> = ({ product, schemas }) => {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const handleProductSelection = () => {
    drawer.setIsOpen(true);
    drawer.setComponent(<EditProduct product={product} schemas={schemas} />)
    drawer.setTitle(t('editProductTitle'))
  }

  const handleCheckboxClicked: MouseEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
  }

  return (
    <div onClick={handleProductSelection} className='grid grid-cols-12 mt-4 h-10 text-gray-500 pb-10 text-sm cursor-pointer hover:bg-gray-50'>
      <div className='col-span-1 flex justify-center items-center'><Checkbox onClick={handleCheckboxClicked}/></div>
      <div className='col-span-3 flex justify-start items-center'><ProductColumnName thumbnail={product.thumbnail} name={product.name} barcode={product.barcode} /></div>
      <div className='col-span-3 flex justify-start items-center'><ProductColumnGeneral label={product.category} /></div>
      <div className='col-span-3 flex justify-start items-center'><ProductColumnGeneral label={product.inventoryAmount} /></div>
      <div className='col-span-2 flex justify-start items-center'><ProductColumnStatus status={product.status} /></div>
    </div>
  )
}

export default CatalogProduct;