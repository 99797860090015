import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import SuppliersTable from '../../components/suppliers-table';
import Input from '../../components/input';
import { listSuppliersAsync } from '../../store/redux/suppliers/suppliers-slice';
import { useAppDispatch } from '../../store/hooks';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import ClientsTable from '../../components/clients-table';

const Suppliers = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(listSuppliersAsync({
      page: 0,
      pageSize: 5
    }))
    // eslint-disable-next-line
  }, [])

  return (
    <div className='h-full flex flex-1 p-4 flex-col'>
      <div className="flex mb-6">
        <div className='flex-1 text-xl'>
          {t('suppliersPageTitleLabel')}
        </div>
      </div>
      <div className="ml-4">
        <Input
          classes='w-64'
          id="search-client"
          placeholder={t('suppliersSearchSupplierPlaceholder')}
          onChange={() => { }}
        />
      </div>
      <div className='mt-4 pl-2 mb-1'>
        <Link to='/suppliers' className={`border-b border-black cursor-pointer ${location.pathname === '/suppliers' ? 'text-blue-500 border-blue-500' : ''}`}>Suppliers</Link>
        <span className='border-b border-black pr-2'></span>
        <Link to='/suppliers/invitations' className={`border-b border-black cursor-pointer ${location.pathname === '/suppliers/invitations' ? 'text-blue-500 border-blue-500' : ''}`}>Invitations</Link>
      </div>
      <Routes>
        <Route path='/' element={<SuppliersTable />} />
        <Route path='/invitations' element={<ClientsTable />} />
      </Routes>
    </div>
  )
}

export default Suppliers;