import { FunctionComponent, useEffect, useState } from 'react';
import {
  Outlet,
  useNavigate,
} from 'react-router-dom';
import Loader from '../loader';
import useAuth from '../../hooks/use-auth';
import { useAppDispatch } from '../../store/hooks';
import { iamAsync } from '../../store/redux/user/user-slice';

const ProtectedRoute: FunctionComponent = () => {
  const user = useAuth();

  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleIam = async () => {
      setPageLoading(true)
      if (!user) {
        console.log('Fetching IAM user')
        const response = await dispatch(iamAsync());
        if (!response.payload || (response.payload.appErrors && response.payload.appErrors.length)) {
          return navigate('/login', { replace: true })
        }
      }
      setPageLoading(false)
    }
    
    handleIam();
  }, [dispatch, navigate, user])
  
  if (pageLoading) {
    return (
      <Loader />
    );
  }

  return (
    <Outlet />
  );
};

export default ProtectedRoute;
