import { UpdateProductRequest } from '../store/redux/products/types';
import ProductValueObject from './product-value-object';
import SchemaValueObject from './schema-value-object';

type Input = {
  schemas: SchemaValueObject[],
  product: ProductValueObject
}

class EditProductValueObject {
  private draft: UpdateProductRequest;

  constructor(private c: Input) {
    this.draft = {
      id: c.product.id
    };
  };

  getSchema() {
    if (this.c.schemas.length < 1 || !this.c.product.schemaId) {
      return new SchemaValueObject();
    }

    const schema = this.c.schemas.find(s => s.getSchemaId() === this.c.product.schemaId);
    if (!schema) {
      throw new Error(`No schema found for id ${this.c.product.schemaId}`)
    }

    return schema
  }

  getAttributeValue(name: string, customAttribute: boolean) {
    if (customAttribute) {
      const value = this.c.product.customAttributes.find(a => a.attribute === name);
      if (!value) {
        throw new Error(`Failed to get custome attribute name ${name}`)
      }
      return value;
    }

    // @ts-ignore;
    return this.c.product[name]
  }

  getDraft() {
    return this.draft;
  }

  setDraftAttribute(attribute: string, value: any) {
    // TODO: handle update
    // @ts-ignore
    this.draft[attribute] = value;
  }
}

export default EditProductValueObject;