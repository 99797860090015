import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TablePagination from './table-pagination';

type Props = {
  total: number;
  page: number;
  availableItemsOnPage: number;
  onChangePage: (toPage: number) => void;
}

const TableFooter: FC<Props> = ({ total, page, availableItemsOnPage, onChangePage }) => {
  const pages = Math.ceil(total / availableItemsOnPage);
  const itemsFrom = page * availableItemsOnPage - availableItemsOnPage + 1;
  const itemsTo = Math.min(page * availableItemsOnPage, total);
  const { t } = useTranslation();
  return (
    <div className='flex mr-6 ml-6 text-sm'>
      <div className='flex-1'>{t('tableShowingLabel')} {itemsFrom}-{itemsTo} {t('tableOutOfLabel')} { total } </div>
      <TablePagination 
        onChangePage={onChangePage}
        totalPages={pages}
        page={page}
      />
    </div>
  )
}

export default TableFooter;