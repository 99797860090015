import { LoginRequest, RegisterRequest } from './types';

export function fetchLogin(payload: LoginRequest) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
}

export function fetchIam() {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/iam`, {
    method: 'GET',
    credentials: 'include',
  })
}

export function fetchRegister(payload: RegisterRequest) {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(payload)
  })
}