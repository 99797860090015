import React, { FunctionComponent, PropsWithChildren } from 'react';

export type AuthContextProps = {
}

export const AuthContext = React.createContext<AuthContextProps | any>({});

const AuthProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const value = {
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
