import { Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className='h-14 border-t flex justify-center pt-4'>
      <Typography variant='small'>
        {t('footerCopyrights')}
      </Typography>
    </div>
  )
}

export default Footer;