import { FC, useEffect, useState } from 'react';

type Props = {
  number: number;
  selectedPage: number;
  onChangePage: (toPage: number) => void;
}

const TablePaginationPage: FC<Props>= ({ number, selectedPage, onChangePage }) => {
  const [classes, setClasses] = useState<string>();

  useEffect(() => {
    let className = 'border p-2'
    if (selectedPage === number) {
      className += ' bg-slate-100'
    }
    setClasses(className);
    // eslint-disable-next-line
  }, [selectedPage])

  return (
    <span onClick={() => onChangePage(number)} className={classes}>{number}</span>
  )
}

export default TablePaginationPage;