type ClientStatus = 'ACTIVE' | 'SUSPENDED' | 'PENDING';

export type ClientShape = {
  bussinesName: string;
  contactName: string;
  email: string;
  id: string;
  phone: string;
  status: ClientStatus
}

class ClientValueObject {
  readonly bussinesName: string;
  readonly contactName: string;
  readonly email: string;
  readonly id: string;
  readonly status: ClientStatus
  readonly phone: string;

  constructor(i: ClientShape) {
    this.bussinesName = i.bussinesName;
    this.contactName = i.contactName;
    this.email = i.email;
    this.id = i.id;
    this.phone = i.phone;
    this.status = i.status;
  }
}

export default ClientValueObject;