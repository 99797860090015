export type SupplierShape = {
  id: string;
  bussinesName: string;
  phone: string;
  status: 'ACTIVE' | 'SUSPENDED' | 'PENDING';
  supplierEmail: string;
  supplierContactName: string;
}

class SupplierValueObject {
  readonly id: string;
  readonly bussinesName: string;
  readonly phone: string;
  readonly status: 'ACTIVE' | 'SUSPENDED' | 'PENDING';;
  readonly supplierEmail: string;
  readonly supplierContactName: string;

  constructor(s: SupplierShape) {
    this.id = s.id;
    this.bussinesName = s.bussinesName;
    this.phone = s.phone;
    this.status = s.status;
    this.supplierEmail = s.supplierEmail;
    this.supplierContactName = s.supplierContactName;
  }
}

export default SupplierValueObject;