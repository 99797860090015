import { FC, useState } from 'react';
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next';
import { SvgCloudUpload } from '../svg';

type Props = {

}

const DropZone: FC<Props> = () => {
  const [filename, setFilename] = useState<string>('');

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      return;
    }
    setFilename(file.name)
  }, [])

  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 97000,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    }
  })

  return (
    <div
      {...getRootProps()}
      className={`flex border border-dashed p-4 pt-10 pb-10 rounded-2xl bg-gray-50 flex-col justify-center content-center text-center ${isDragActive && 'bg-gray-200'}`
      }>
      <input {...getInputProps()} />
      <span className='flex justify-center'>
        <SvgCloudUpload />
      </span>
      <span>
        {
          filename || (
            <>
              <p>{t('addProductDragInfoText')}</p>
              <p>{t('addProductDragConstrainsText')}</p>
            </>
          )
        }
      </span>
    </div>
  )
}

export default DropZone;