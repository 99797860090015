
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SupplierValueObject, { SupplierShape } from '../../../value-objects/supplier-value-object';
import { RootState } from '../../store';
import { fetchListSuppliers } from './suppliers-api';
import { RequestListSuppliers, State } from './types';

const initialState: State = {
  suppliers: [],
  total: 0,
};

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listSuppliersAsync.fulfilled, (state, action) => {
        state.total = action.payload.total;
        state.suppliers = action.payload.suppliers.map((i: SupplierShape) => new SupplierValueObject(i));
      })
  },
});

export const listSuppliersAsync = createAsyncThunk(
  'suppliers/list',
  async (payload: RequestListSuppliers) => {
    try {
      const response = await fetchListSuppliers(payload);
      return response.json();
    } catch (e) {
      console.log(e);
    }
  }
);

// Reducer
export default suppliersSlice.reducer;

// Selectors
export const selectTotalSuppliers = (state: RootState) => state.suppliers.total;
export const selectSuppliers = (state: RootState) => state.suppliers.suppliers;