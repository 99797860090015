import { FC } from 'react';

type Props = {
  name: string;
  clientId: string;
}

const ClientColumnName: FC<Props> = ({ name, clientId }) => {
  return (
    <div className='flex items-center'>
      <div>
        <div>
          {name}
        </div>
        <div>
          {clientId}
        </div>
      </div>
    </div>
  )
}

export default ClientColumnName;