import { Checkbox } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import { selectSuppliers, selectTotalSuppliers } from '../../store/redux/suppliers/suppliers-slice';
import SupplierItem from '../supplier';
import TableFooter from '../table/table-footer';


const SuppliersTable = () => {
  const { t } = useTranslation();
  const totalSuppliers = useAppSelector(selectTotalSuppliers);
  const suppliers = useAppSelector(selectSuppliers);
  return (
    <>
      <div className='grid grid-cols-12 border-b-2 h-10 text-gray-500 text-sm'>
        <div className='bg-slate-100 col-span-1 rounded-tl-lg flex justify-center items-center'><Checkbox /></div>
        <div className='bg-slate-100 col-span-4 flex justify-start items-center'>{t('supplierTableHeaderNameLabel')}</div>
        <div className='bg-slate-100 col-span-4 flex justify-start items-center'>{t('supplierTableHeaderPendingOrderLabel')}</div>
        <div className='bg-slate-100 col-span-3 flex justify-start items-center'>{t('supplierTableHeaderStatusLabel')}</div>
      </div>
      { suppliers.map(supplier => {
        return <SupplierItem key={supplier.id} supplier={supplier} />;
      })}
      <div className='border mb-4 mt-4'></div>
      <TableFooter 
      // TODO: handle page change
        onChangePage={() => {}}
        total={totalSuppliers}
        page={1}
        availableItemsOnPage={5}
      />
    </>
  )
}

export default SuppliersTable;