import { Checkbox } from '@material-tailwind/react'
import { FC } from 'react';
import { useDrawer } from '../../providers/drawer-provider';
import SupplierValueObject from '../../value-objects/supplier-value-object';
import ViewSupplier from '../view-supplier';
import ColumnGeneral from './supplier-column-general';
import ColumnName from './supplier-column-name';
import ColumnStatus from './supplier-column-status';

type Props = {
  supplier: SupplierValueObject;
}

const SupplierItem: FC<Props> = ({ supplier }) => {

  const drawer = useDrawer();

  const handleSelect = () => {
    drawer.setIsOpen(true);
    drawer.setTitle(supplier.bussinesName);
    drawer.setComponent(<ViewSupplier supplier={supplier} />);
  }

  // TODO: handle pending orders
  return (
    <div onClick={handleSelect} className='grid grid-cols-12 mt-4 h-10 text-gray-500 pb-10 text-sm cursor-pointer hover:bg-gray-50'>
      <div className='col-span-1 flex justify-center items-center'><Checkbox onClick={() => {}}/></div>
      <div className='col-span-4 flex justify-start items-center'><ColumnName name={supplier.bussinesName} clientId={supplier.id} /></div>
      <div className='col-span-4 flex justify-start items-center'><ColumnGeneral label={0} /></div>
      <div className='col-span-2 flex justify-start items-center'><ColumnStatus status={supplier.status} /></div>
    </div>
  )
}

export default SupplierItem