import { useEffect } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { listInvitesAsync } from '../../store/redux/invites/invites-slice';
import ProfileMenu from '../profile-menu';
import { SvgBell, SvgShoppingCart } from '../svg';

const Header = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listInvitesAsync());
    // eslint-disable-next-line
  }, [])
  
  return (
    <div className="flex flex-1 w-full border-b-2 p-4">
      <div className="text-xl flex-1">
        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt='logo'></img>
      </div>
      <div className='flex items-center'>
        <ProfileMenu />
        <div className='cursor-pointer border-l pl-6 mr-6'> <SvgBell /> </div>
        <div className='flex cursor-pointer items-center text-sm border p-2 rounded-lg'> <span className='mr-2'><SvgShoppingCart /></span><span>Cart is empty</span></div>
      </div>
    </div>
  )
}

export default Header;