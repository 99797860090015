import { ChangeEventHandler, forwardRef } from 'react';
import Input from '../input';

type Props = {
  type: 'text' | 'radio' | 'checkbox' | 'selection'
  onChange: ChangeEventHandler<HTMLInputElement>
  id: string;
  placeholder: string;
  name: string;
  options?: string[];
  label?: string;
};

const CustomAttributes = forwardRef<HTMLInputElement, Props>((props, inputRef) => {
  const {
    type,
    id,
    placeholder,
    onChange,
    name,
    options,
    label,
  } = props;

  switch (type) {
    case 'text': {
      return (
        <li className='mb-4'>
          <Input
            ref={inputRef}
            label={label}
            name={name}
            id={id}
            placeholder={placeholder}
            onChange={onChange}
            classes="w-full"
          />
        </li>
      )
    }

    case 'radio': {
      if (!options) {
        throw new Error('missing options')
      }
      return (
        <li className='mb-4'>
          <div className='block mb-2 text-sm font-medium text-gray-900'>{name}</div>
          {
            options.map(o => (
              <span className='mr-2 text-sm' key={o}>
                <input
                  onChange={onChange}
                  ref={inputRef}
                  className='mr-2'
                  id={`edit-product-custom-attribute-${o}`}
                  name={name}
                  type='radio'
                  value={o}
                />
                <label htmlFor={`edit-product-custom-attribute-${o}`}>{o}</label>
              </span>
            ))
          }
        </li>
      )
    }
  }

  return null;
});

export default CustomAttributes;