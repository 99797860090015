import React, { FunctionComponent, PropsWithChildren, useContext, useState } from 'react';

interface ModalContextProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  Component: JSX.Element | null;
  setComponent: (component: JSX.Element) => void;
  title: string,
  setTitle: (value: string) => void;
}

export const ModalContext = React.createContext<ModalContextProps>(undefined!);

const ModalProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [Component, setComponent] = useState<JSX.Element | null>(null);

return (
    <ModalContext.Provider value={{
      setIsOpen,
      isOpen,
      Component,
      setComponent,
      title,
      setTitle,
    }}>
      {children}
    </ModalContext.Provider>
  );
};

// Custom context hook
const useModal = () => {
  return useContext(ModalContext);
};

export { ModalProvider, useModal };
