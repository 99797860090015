import { FormEventHandler, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@material-tailwind/react';
import { useAppDispatch } from '../../store/hooks';
import Input from '../input';
import { loginAsync, setIamUser } from '../../store/redux/user/user-slice';

const LoginView = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const navigate = useNavigate()
    const dispatch = useAppDispatch();

    const handleLogin: FormEventHandler<HTMLFormElement> = async (e) => {


        e.preventDefault();
        if (!username || !password) {
            // TODO: handle error message
            return
        }

        const response = await dispatch(loginAsync({ username, password }));
        // if (response.payload.appErrors && response.payload.appErrors.length > 0) {
        // setErrorCode(response.payload.appErrors[0])
        // return;
        // }

        dispatch(setIamUser(response.payload))
        navigate('/')


    }

    const { t } = useTranslation();
    return (
        <div className='md:w-1/4 w-3/4 bg-white flex min-h-96 p-8 pt-0 flex-col rounded'>
            <div className="text-4xl mb-6 pt-6">
              <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt='logo'></img>
            </div>
            <div className="text-xl mb-6">
                {t('loginSignInTitle')}
            </div>
            <div>
                <form onSubmit={handleLogin}>
                    <div>
                        <div className='mb-6'>
                            <Input
                                onChange={e => setUsername(e.target.value)}
                                id='username'
                                placeholder='john@doe.com'
                                type='text'
                                label={t('loginUseNameLabel')}
                                classes="w-full"
                                required
                                />
                        </div>
                        <div className="mb-6">
                            <Input
                                onChange={e => setPassword(e.target.value)}
                                id='password'
                                placeholder='•••••••••••'
                                type='password'
                                label={t('loginPasswordLabel')}
                                classes="w-full"
                                required
                            />
                        </div>
                        <div className='flex justify-between content-center items-center mb-2 text-xs'>
                            <Checkbox className="text-sm" label={t('loginRememberMeLabel')} />
                            <div className='text-blue-600 mr-4'>
                                <a href="/#">Lost Password?</a>
                            </div>
                        </div>
                        <div>
                            <Button className="normal-case" type='submit' fullWidth>{t('loginContinueButtonLabel')}</Button>
                        </div>
                    </div>
                </form>
                <div className="text-sm mt-4">
                    <span className="text-gray-600">
                        {t('loginNotRegisteredText')}
                    </span>
                    <span className="text-blue-600 ml-1">
                        <Link to="/register">{t('loginCreateAccountText')}</Link>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default LoginView;