import { useTranslation } from 'react-i18next';
import NavigationItem from '../navigation-item';
import { SvgPieChart, SvgShoppingBag } from '../svg';

const Navigation = () => {
  const {t} = useTranslation()

  return (
    <div className='w-72 p-4 h-full border-r-2'>
      <ol className='mr-4'>
          <NavigationItem
            path='/catalog'
            label={t('menuCatalogLabel')}
            SvgIcon={SvgShoppingBag}
            />
          <NavigationItem
            path='/clients'
            label={t('menuClientsLabel')}
            SvgIcon={SvgPieChart}
            />
          <NavigationItem
            path='/suppliers'
            label={t('menuSuppliersLabel')}
            SvgIcon={SvgPieChart}
            />
          <NavigationItem
            path='/orders'
            label={t('menuOrderLabel')}
            SvgIcon={SvgPieChart}
            />
          <NavigationItem
            path='/agents'
            label={t('menuAgentsLabel')}
            SvgIcon={SvgPieChart}
          />
      </ol>
    </div>
  )
}

export default Navigation;